import React from 'react'

export default function EtapasCard({
  titulo,
  subtitulo,
  texto,
  desc1,
  desc2,
  desc3,
}) {
  return (
    <div className="px-6 pt-8 pb-14 bg-white rounded-xl border-2 border-cyan-400 flex-col justify-start items-start gap-3 inline-flex">
      <span className="text-cyan-400 text-base font-medium leading-snug w-full max-w-[404px]">
        {titulo}
      </span>
      <h3 className="text-slate-800 text-xl font-medium leading-relaxed">
        {subtitulo}
      </h3>
      <p className="text-slate-800 text-base font-normal leading-normal">
        {texto}
      </p>
      <div className=" mt-2 pl-10 flex-col justify-start items-start inline-flex">
        <div className="pr-3.5 pb-px justify-start items-start inline-flex space-x-2">
          <p className="text-slate-800">•</p>
          <p className="text-slate-800 text-base font-normal leading-normal">
            {desc1}
          </p>
        </div>
        <div className="pr-2 pb-px justify-start items-start inline-flex space-x-2">
          <p className="text-slate-800">•</p>
          <p className="text-slate-800 text-base font-normal leading-normal">
            {desc2}
          </p>
        </div>
        <div className="pr-5 pb-px justify-start items-start inline-flex space-x-2">
          <p className="text-slate-800">•</p>
          <p className="text-slate-800 text-base font-normal leading-normal">
            {desc3}
          </p>
        </div>
      </div>
    </div>
  )
}
