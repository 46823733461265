import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import * as _redux from "./redux";
import store, { persistor } from "./redux/store";
import App from "./App";


/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;



_redux.middlewareAxios(axios, store);

ReactDOM.render(
    <App store={store} persistor={persistor} basename={PUBLIC_URL} />,
    document.getElementById("root")
);
