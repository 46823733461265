import React from 'react'

export default function NossosNumerosCard({ title, descricao }) {
  return (
    <div
      className="xl:w-[22.75rem] w-full xl:h-[12.813rem] h-[12.813rem] flex justify-start items-center gap-4 p-8"
      style={{
        backgroundImage: 'url(/assets/img/Nossos-numeros-cards.png)',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      }}>
      <div className="">
        <p className="xl:w-[22.75rem] w-fit xl:text-[2.9rem] text-[2.3rem] text-slate-800 font-bold whitespace-nowrap">
          {title}
        </p>
        <p className="xl:text-[1.5rem] text-[1rem] text-slate-800">
          {descricao}
        </p>
      </div>
    </div>
  )
}
