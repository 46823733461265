import React from 'react'
import NossosNumerosCard from '../../../../../components/ui/NossosNumerosCard'

export default function NossosNumeros() {
  return (
    <div className="w-full max-w-7xl flex flex-col items-center justify-around gap-8 px-4 xl:px-0 pb-20 mx-auto">
      <div className="w-full flex  justify-start items-center">
        <h4 className="w-full text-slate-800 lg:text-5xl text-4xl font-medium text-left">
          Nossos números
        </h4>
      </div>
      <div className="grid lg:grid-cols-3 grid-cols-1 gap-5 w-full justify-around items-center lg:mt-16 mt-8">
        <NossosNumerosCard title="+25 anos" descricao="de mercado" />
        <NossosNumerosCard
          title="+3 Bilhões"
          descricao="de reais processados e gerenciados anualmente"
        />
        <NossosNumerosCard
          title="+250 mil faturas"
          descricao="de energia processadas mensalmente"
        />
      </div>
      <a
        target="_blank"
        className="w-fit mt-16 h-11 px-8 py-3 bg-cyan-400 hover:bg-cyan-200 transition ease-in-out duration-200 rounded-full border border-cyan-400 justify-center items-start flex disabled:opacity-50 disabled:cursor-not-allowed"
        href="https://wa.me/5515998238806?text=Ol%C3%A1!%20Sou%20cliente%20Vivo%20Valoriza%20Empresas%20e%20quero%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20servi%C3%A7o%20de%20Gest%C3%A3o%20de%20Faturas">
        <div className="text-center text-slate-800 text-sm font-medium leading-tight">
          Entre em contato conosco
        </div>
      </a>
    </div>
  )
}
