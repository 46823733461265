import React, { useEffect } from 'react'

import MelhoresOportunidades from './components/MelhoresOportunidades'
import EmpresasParceiras from './components/EmpresasParceiras'
import Footer from '../../../../layout/components/Footer'
import Header from '../../../../layout/components/Header'
import NossosNumeros from './components/NossosNumeros'
import OqueFazemos from './components/OqueFazemos'
import Vantagens from './components/Vantagens'
import FormWhite from './components/FormWhite'
import HeroWhite from './components/HeroWhite'
import Auditoria from './components/Auditoria'
import Faq from './components/Faq'

export default function Main() {
  useEffect(() => {
    localStorage.removeItem('@dados_proposta_amee')
    localStorage.removeItem('proposta_nome')
  }, [])

  return (
    <div>
      <div>
        <Header />
      </div>
      <section>
        <HeroWhite />
      </section>
      <section id="form-white-contato">
        <FormWhite />
      </section>
      <section>
        <Auditoria />
      </section>
      <section>
        <Vantagens />
      </section>
      <section>
        <OqueFazemos />
      </section>
      <section>
        <MelhoresOportunidades />
      </section>
      <section>
        <EmpresasParceiras />
      </section>
      <section>
        <NossosNumeros />
      </section>
      <section>
        <Faq />
      </section>
      <Footer />
    </div>
  )
}
