import React from 'react'

export default function MelhoresPropostasCard({ imagePath, title }) {
  return (
    <div className="w-fit max-w-[332px] h-12 justify-start items-center gap-4 inline-flex">
      <div className="min-w-[3rem] min-h-[3rem] w-12 h-12 bg-cyan-100 rounded justify-center items-center gap-4 flex">
        <img src={imagePath} alt="Oportunidades_item_ilustration" />
      </div>
      <div className="w-fit text-white text-sm font-medium">{title}</div>
    </div>
  )
}
