import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { LinearProgress } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { isNumber } from 'lodash'
import { isObject } from 'lodash'

import PropostaEnviadaInfosCards from '../../../../../components/ui/PropostaEnviadaInfosCards'
import PropostaEnviadaVideo from '../../../../../components/ui/PropostaEnviadaVideo'
import { incluirLeadGeneric } from '../../../../../pages/api/simulacaoLead'
import OfertaEnviadaModal from './OfertaEnviadaModal'
import { getResultadoProposta } from '../../../../../service/Api'

export default function ProstaEnviadaInfos() {
  const navigate = useNavigate()

  const [propostaDados] = useState(
    JSON.parse(localStorage.getItem('@dados_proposta_amee')) || null
  )
  const [nome] = useState(localStorage.getItem('proposta_nome') || 'Senhor(a)')
  const [showModalOfertaEnviada, setShowModalOfertaEnviada] = useState(false)
  const [orcamentoLoading, setOrcamentoLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [proposta, setProposta] = useState(null)

  useEffect(() => {
    setIsLoading(true)
    getResultadoProposta()
      .then((res) => {
        if (!isObject(res.data)) {
          window.scrollTo(0, 0)
          window.scrollTo(0, 0)
          navigate('/proposta-nao-enviada')
        }
        setProposta(res.data)
        setIsLoading(false)
      })
      .catch(() => {
        window.scrollTo(0, 0)
        navigate('/proposta-nao-enviada')
      })
  }, [])

  const valorKW = useMemo(() => {
    if (!proposta) return 0
    const itensParaSomar = [
      proposta.dados_de_leitura_da_fatura.consumo.quantidade,
      proposta.dados_de_leitura_da_fatura.consumo_fp.quantidade,
      proposta.dados_de_leitura_da_fatura.consumo_p.quantidade,
    ]
    let soma = 0
    itensParaSomar.forEach((item) => {
      if (isNumber(item)) {
        soma += parseFloat(item)
      }
    })
    return soma
  }, [proposta])

  const valorPagar = useMemo(() => {
    if (proposta && proposta.dados_de_leitura_da_fatura) {
      return proposta.dados_de_leitura_da_fatura.total.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      })
    } else {
      return 'N/A'
    }
  }, [proposta])

  const valorOportunidade = useMemo(() => {
    if (proposta && proposta.dados_de_oportunidade) {
      if (isNumber(proposta.dados_de_oportunidade.total_oportunidade)) {
        return proposta.dados_de_oportunidade.total_oportunidade.toLocaleString(
          'pt-br',
          {
            style: 'currency',
            currency: 'BRL',
          }
        )
      } else {
        return 'N/A'
      }
    } else {
      return 'N/A'
    }
  }, [proposta])

  const mensageProposta = useMemo(() => {
    if (valorPagar !== 'N/A' && valorKW !== 0) {
      return (
        <p className="w-full max-w-[771px] text-[#142a42] lg:text-[28px] text-xl font-bold font-['Plus Jakarta Sans'] lg:leading-[35px]">
          {`Você consome ${valorKW} kWh de energia e paga ${valorPagar} por mês.`}
          <br />
          {`Você pode economizar até ${valorOportunidade} por mês.`}
        </p>
      )
    } else {
      return 'Você paga R$ 140,00 por mês. Descubra novas maneiras de otimizar seu consumo e potencializar suas economias com nossa análise detalhada.'
    }
  }, [valorPagar, valorOportunidade, proposta, valorKW])

  const handleEnviarOrcamento = useCallback(async () => {
    setOrcamentoLoading(true)
    const el = document.createElement('a')
    el.href =
      'https://api.whatsapp.com/send/?phone=5515998238806&text=Ol%C3%A1%21+Sou+cliente+Vivo+Valoriza+Empresas+e+quero+mais+informa%C3%A7%C3%B5es+sobre+o+servi%C3%A7o+de+Gest%C3%A3o+de+Faturas&type=phone_number&app_absent=0'
    el.target = '_blank'
    el.click()
    toast.success('Oferta enviada com sucesso', {
      autoClose: 10000,
    })
    setOrcamentoLoading(false)
    setShowModalOfertaEnviada(true)
  }, [toast, incluirLeadGeneric, setOrcamentoLoading])

  if (isLoading) {
    return (
      <div className="w-full h-[90vh] pt-28">
        <LinearProgress color="primary" />
      </div>
    )
  }

  return (
    <>
      <OfertaEnviadaModal
        open={showModalOfertaEnviada}
        setOpen={setShowModalOfertaEnviada}
      />
      <div className="z-10 md:px-0 h-[60rem] overflow-hidden px-2 lg:pb-24 pb-12 pt-[10rem] lg:pt-[13rem] relative w-full flex justify-center items-center bg-transparent">
        <img
          src="/assets/img/blur_blue2.svg"
          alt="Fundo ilustração"
          className="absolute -top-[16.688rem] -right-20 w-full z-10"
        />
      </div>
      <div className="-mt-[60rem] z-20 w-full max-w-7xl flex flex-col items-center justify-center lg:gap-8 px-4 mx-auto xl:px-0 relative lg:pt-20 pt-0 lg:pb-24 pb-16">
        <div className="flex lg:flex-row flex-col justify-between items-center w-full pt-8 gap-8">
          <div className="space-y-8 lg:-mt-[10rem]">
            <h3 className="w-full max-w-[35.688rem] text-slate-800 lg:text-[28px] text-xl font-medium lg:leading-[35px]">
              {`Obrigado, ${nome}`}
              <br />
              Avaliamos seu caso e descobrimos que…
            </h3>
            {mensageProposta}
            <p className="w-full max-w-[771px] text-[#142a42] lg:text-[28px] text-xl font-medium font-['Plus Jakarta Sans'] lg:leading-[35px]">
              Analisando os 6 itens das 250 oportunidades possíveis mapeadas
              pela Amee chegamos na seguinte conclusão:
            </p>
          </div>
          <div className="bg-[#F7F4F0] w-full max-w-[35.438rem] px-9 py-8 rounded-2xl border border-[#142a42]">
            <div className="w-full flex justify-between items-center gap-2 mt-2 mb-8">
              <p className="uppercase font-semibold text-xl">
                FATURA DE ENERGIA
              </p>
              <img
                src="/assets/img/logotipo.svg"
                alt="Logotipo Amee"
                className="w-[74px]"
              />
            </div>
            <PropostaEnviadaInfosCards proposta={proposta} />
            <div className="my-8 w-full flex justify-center items-center">
              {[...new Array(1)].map((_, index) => (
                <img
                  key={index}
                  src="/assets/img/bar-code.png"
                  alt="código_barras_ilustração"
                />
              ))}
            </div>
            <div className="w-full flex justify-between items-center gap-2 mt-2">
              <p className="font-semibold text-xl">Economia de até:</p>
              <div className="bg-cyan-400 rounded-2xl py-3 px-10 font-semibold border border-[#142a42]">
                {valorOportunidade}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex lg:flex-row flex-col lg:justify-start justify-between items-center gap-[50px] lg:pt-16 pt-11 pb-1">
          <PropostaEnviadaVideo />
        </div>
        <div className="w-full max-w-[857px] space-y-4 mb-5">
          <div className="w-full text-center text-[#142a42] lg:text-[28px] text-base font-normal font-['Plus Jakarta Sans'] lg:leading-[39.20px]">
            Se quiser aproveitar a oportunidade para monitorar mensalmente esses
            250 campos clique aqui e contrate a Amee.
          </div>
          <div className="text-center text-[#142a42] lg:text-[28px] text-base font-bold font-['Plus Jakarta Sans'] lg:leading-[35px]">
            Planos a partir de R$6,00
          </div>
        </div>
        <div className="w-full flex lg:flex-row flex-col justify-center items-center gap-4 lg:px-16 px-2 lg:mt-0 mt-5">
          <button
            disabled={orcamentoLoading}
            onClick={handleEnviarOrcamento}
            className="w-full h-14 px-8 py-3 bg-white hover:opacity-80 transition ease-in-out duration-200 rounded-full border border-cyan-400 justify-center items-center flex">
            <div className="text-center text-slate-800 text-sm font-medium leading-tight">
              {orcamentoLoading
                ? 'Enviando oferta...⌛'
                : 'Quero contratar um Gestor de Energia'}
            </div>
          </button>
          <a
            href="https://api.whatsapp.com/send/?phone=5515998238806&text=Ol%C3%A1%21+Sou+cliente+Vivo+Valoriza+Empresas+e+quero+mais+informa%C3%A7%C3%B5es+sobre+o+servi%C3%A7o+de+Gest%C3%A3o+de+Faturas&type=phone_number&app_absent=0"
            target="_blank"
            className="w-full h-14 px-8 py-3 bg-cyan-400 hover:bg-cyan-200 transition ease-in-out duration-200 rounded-full border border-cyan-400 justify-center items-center flex">
            <div className="text-center text-slate-800 text-sm font-medium leading-tight">
              Quero falar com um especialista
            </div>
          </a>
        </div>
      </div>
    </>
  )
}
