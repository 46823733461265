import React from 'react'
import { isNumber } from 'lodash'

export default function PropostaEnviadaInfosCards({ proposta }) {
  return (
    <div className="w-full border border-[#142a42] rounded-xl overflow-hidden">
      {/* Card com as três infomações, tipo uma table */}
      <div className="w-full">
        <div className="grid grid-cols-3 gap-3 w-full h-[4.25rem] relative bg-cyan-400 rounded-tl-xl rounded-tr-xl border-b border-[#142a42]">
          <p className="text-slate-800 lg:text-base text-xs font-medium flex items-center justify-center text-center border-r border-[#142a42]">
            Conta de <br />
            {proposta && proposta.dados_de_leitura_da_fatura
              ? proposta.dados_de_leitura_da_fatura.classificacao === 'A'
                ? 'Alta Tensão: '
                : 'Média Tensão: '
              : 'N/A'}
          </p>
          <p className="text-slate-800 lg:text-base text-xs font-medium flex items-center justify-center border-r border-[#142a42]">
            Multas
          </p>
          <p className="text-slate-800 lg:text-base text-xs font-medium flex items-center justify-center">
            Juros
          </p>
        </div>
        <div className="grid grid-cols-3 gap-3 w-full h-[4.25rem] relative bg-[#F7F4F0]">
          <p className="text-slate-800 lg:text-base text-xs font-medium flex items-center justify-center text-center border-r border-[#142a42]">
            {proposta && proposta.dados_de_leitura_da_fatura
              ? proposta.dados_de_leitura_da_fatura.total.toLocaleString(
                  'pt-br',
                  {
                    style: 'currency',
                    currency: 'BRL',
                  }
                )
              : 'N/A'}
          </p>
          <p className="text-slate-800 lg:text-base text-xs font-medium flex items-center justify-center border-r border-[#142a42]">
            {proposta && proposta.dados_de_oportunidade
              ? isNumber(proposta.dados_de_oportunidade.multa)
                ? proposta.dados_de_oportunidade.multa.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                : proposta.dados_de_oportunidade.multa.includes('R$')
                ? proposta.dados_de_oportunidade.multa
                : 'N/A'
              : 'N/A'}
          </p>
          <p className="text-slate-800 lg:text-base text-xs font-medium flex items-center justify-center">
            {proposta && proposta.dados_de_oportunidade
              ? isNumber(proposta.dados_de_oportunidade.juros)
                ? proposta.dados_de_oportunidade.juros.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                : proposta.dados_de_oportunidade.juros.includes('R$')
                ? proposta.dados_de_oportunidade.juros
                : 'N/A'
              : 'N/A'}
          </p>
        </div>
      </div>

      {/* Card simples */}
      <div className="w-full">
        <div className="grid grid-cols-1 gap-3 w-full h-[4.25rem] bg-cyan-400 p-5 border-y border-[#142a42]">
          <div className="w-full">
            <p className="text-slate-800 lg:text-base text-xs font-medium flex items-center justify-start">
              UFER
            </p>
            <p className="w-full text-slate-800 text-xs font-normal">
              Unidade de faturamento de energia elétrica reativa excedente
            </p>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-3 w-full h-[4.25rem] relative bg-[#F7F4F0] p-5">
          <p className="text-slate-800 lg:text-base text-xs font-medium flex items-center justify-start">
            {proposta && proposta.dados_de_oportunidade
              ? isNumber(proposta.dados_de_oportunidade.ufer)
                ? proposta.dados_de_oportunidade.ufer.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                : proposta.dados_de_oportunidade.ufer.includes('R$')
                ? proposta.dados_de_oportunidade.ufer
                : 'N/A'
              : 'N/A'}
          </p>
        </div>
      </div>

      {/* Card simples */}
      <div className="w-full">
        <div className="grid grid-cols-1 gap-3 w-full h-[4.25rem] relative bg-cyan-400 p-5 border-y border-[#142a42]">
          <div className="w-full">
            <p className="text-slate-800 lg:text-base text-xs font-medium flex items-center justify-start">
              UFDR
            </p>
            <p className="w-fit text-slate-800 text-xs font-normal">
              Unidade de faturamento de demanda reativa excedente
            </p>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-3 w-full h-[4.25rem] relative bg-[#F7F4F0] p-5">
          <p className="text-slate-800 lg:text-base font-medium flex items-center justify-start">
            {proposta && proposta.dados_de_oportunidade
              ? isNumber(proposta.dados_de_oportunidade.ufdr)
                ? proposta.dados_de_oportunidade.ufdr.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                : proposta.dados_de_oportunidade.ufdr.includes('R$')
                ? proposta.dados_de_oportunidade.ufdr
                : 'N/A'
              : 'N/A'}
          </p>
        </div>
      </div>
    </div>
  )
}
