import React, { useEffect, useState } from 'react'

import { CircularProgress } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { getStatuUpload } from '../../../../service/Api'

export default function RedirectProposta() {
  const [timer, setTimer] = useState(60)
  const navigate = useNavigate()

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prev) => prev - 1)
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      getStatuUpload()
        .then((res) => {
          if (res.status === 'callback_finished') {
            clearInterval(interval)
            window.scrollTo(0, 0)
            navigate('/proposta-enviada')
          }
        })
        .catch(() => {
          clearInterval(interval)
          window.scrollTo(0, 0)
          navigate('/proposta-nao-enviada')
        })
    }, 10000)

    const timeout = setTimeout(() => {
      clearInterval(interval)
      window.scrollTo(0, 0)
      navigate('/proposta-nao-enviada')
    }, 60000)

    return () => {
      clearInterval(interval)
      clearTimeout(timeout)
    }
  }, [navigate])

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen overflow-hidden bg-gray-200 opacity-75 flex flex-col items-center justify-center">
      <div className="flex flex-col justify-center items-center gap-4">
        <img src="/assets/img/vivo_logo.svg" alt="Logotipo Vivo Valoriza" />
        <img src="/assets/img/logotipo.svg" alt="Logotipo Amee" />
      </div>
      <div className="relative w-1/6  bg-gray-200 rounded">
        <div className="w-full absolute top-0 h-4 rounded shim-green" />
      </div>
      <div className="text-center w-36 mt-3 space-y-3">
        <p>
          <strong className="animate-pulse">{`Aguarde... ${timer}`}</strong>
        </p>
        <CircularProgress color="primary" />
      </div>
    </div>
  )
}
