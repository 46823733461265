import React from 'react'

export default function QueroOrcamento() {
  return (
    <div
      className={`z-20 w-full md:min-h-[750px] min-h-[28rem] flex lg:items-center items-end justify-start `}
      style={{
        backgroundImage: 'url(/assets/img/quero_orcamento_imagem.png)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        objectFit: 'cover',
      }}>
      <div className="z-20 w-full md:py-8 py-12 relative max-w-7xl mx-auto flex flex-col px-4 items-start lg:justify-center justify-end lg:gap-[61px] gap-5 xl:px-0">
        <h3 className="w-full lg:max-w-[35.875rem] max-w-[11.8rem] text-white lg:text-5xl text-[18px] font-medium lg:leading-[72px]">
          Entenda de uma vez por todas a sua conta de luz e pague apenas o que
          realmente deve.
        </h3>
        <a
          target="_blank"
          href="https://api.whatsapp.com/send/?phone=5515998238806&text=Ol%C3%A1%21+Sou+cliente+Vivo+Valoriza+Empresas+e+quero+mais+informa%C3%A7%C3%B5es+sobre+o+servi%C3%A7o+de+Gest%C3%A3o+de+Faturas&type=phone_number&app_absent=0"
          className="h-12 px-12 bg-white hover:bg-slate-300 transition ease-in-out duration-200 rounded-full flex justify-center items-center">
          <div className="text-center text-slate-800 text-base font-semibold leading-tight">
            Quero orçamento
          </div>
        </a>
      </div>
    </div>
  )
}
