import React from 'react'

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  accordionClasses,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export default function OqueFazemos() {
  return (
    <>
      <div className="w-full max-w-7xl flex lg:flex-row flex-col items-center justify-center gap-8 px-4 xl:px-0 py-20 mx-auto">
        <div className="z-20">
          <h3 className="w-full max-w-[49.5rem] text-center text-slate-800 lg:text-5xl text-4xl font-medium  lg:leading-[64.80px]">
            Tudo que um Gestor de Energia faz pela sua empresa
          </h3>
          <div className="w-full max-w-[49.5rem] mt-16 py-5 px-2">
            <Accordion
              variant="outlined"
              sx={{
                border: 'none',
                borderRadius: '0px',
                borderTop: '1px solid #CED6D7',
                [`&.${accordionClasses.root}`]: {
                  borderTop: 'none !important',
                },
              }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header">
                Por que a auditoria de faturas é essencial?
              </AccordionSummary>
              <AccordionDetails>
                <div className="space-y-3">
                  <div className="w-full flex justify-start items-start pl-4 gap-3">
                    <p className="text-slate-800">•</p>
                    <p className="text-slate-800">
                      É estimado que as distribuidoras de energia tenham
                      faturado mais de R$ 300 bilhões só em 2023.
                    </p>
                  </div>
                  <div className="w-full flex justify-start items-start pl-4 gap-3">
                    <p className="text-slate-800">•</p>
                    <p className="text-slate-800">
                      O que você talvez não saiba é que os erros de faturamento
                      representam aproximadamente 3% no valor das contas de
                      energia, ou seja, isso significa um valor aproximado de R$
                      9 bilhões em cobranças indevidas por ano.
                    </p>
                  </div>
                  <div className="w-full flex justify-start items-start pl-4 gap-3">
                    <p className="text-slate-800">•</p>
                    <p className="text-slate-800">
                      As principais reclamações de erros em faturas de energia
                      estão relacionadas a mercado livre de energia, geração
                      distribuída e qualidade de rede.
                    </p>
                  </div>
                  <div className="w-full flex justify-start items-start pl-4 gap-3">
                    <p className="text-slate-800">•</p>
                    <p className="text-slate-800">
                      A Amee examina e cuida de todos esses pontos nas
                      auditorias de faturas para garantir que sua empresa não
                      pague nem um centavo em cobranças indevidas.
                    </p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              variant="outlined"
              sx={{
                border: 'none',
                borderRadius: '0px',
                borderTop: '1px solid #CED6D7',
              }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header">
                Monitoramento e Análise de Consumo e Custos de Energia
              </AccordionSummary>
              <AccordionDetails>
                <div className="space-y-3">
                  <div className="w-full flex justify-start items-center pl-4 gap-3">
                    <p className="text-slate-800">•</p>
                    <p className="text-slate-800">
                      Acompanha e analisa os padrões de consumo de energia
                      elétrica
                    </p>
                  </div>
                  <div className="w-full flex justify-start items-center pl-4 gap-3">
                    <p className="text-slate-800">•</p>
                    <p className="text-slate-800">
                      Identifica áreas de desperdício de energia
                    </p>
                  </div>
                  <div className="w-full flex justify-start items-center pl-4 gap-3">
                    <p className="text-slate-800">•</p>
                    <p className="text-slate-800">
                      Detecta  oportunidades para melhoria da eficiência
                    </p>
                  </div>
                  <div className="w-full flex justify-start items-center pl-4 gap-3">
                    <p className="text-slate-800">•</p>
                    <p className="text-slate-800">
                      Auditora o consumo e custo das faturas de energia
                    </p>
                  </div>
                  <div className="w-full flex justify-start items-center pl-4 gap-3">
                    <p className="text-slate-800">•</p>
                    <p className="text-slate-800">
                      Solicita a restituição de valores com as concessionárias
                    </p>
                  </div>
                  <div className="w-full flex justify-start items-center pl-4 gap-3">
                    <p className="text-slate-800">•</p>
                    <p className="text-slate-800">
                      Elabora relatórios sobre o consumo de energia
                    </p>
                  </div>
                  <div className="w-full flex justify-start items-center pl-4 gap-3">
                    <p className="text-slate-800">•</p>
                    <p className="text-slate-800">
                      Demonstra resultados das iniciativas de eficiência
                      energética
                    </p>
                  </div>
                  <div className="w-full flex justify-start items-center pl-4 gap-3">
                    <p className="text-slate-800">•</p>
                    <p className="text-slate-800">
                      Monitora indicadores de desempenho energético (KPI)
                    </p>
                  </div>
                  <div className="w-full flex justify-start items-center pl-4 gap-3">
                    <p className="text-slate-800">•</p>
                    <p className="text-slate-800">
                      Avalia a eficácia das ações implementadas
                    </p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              variant="outlined"
              sx={{
                border: 'none',
                borderRadius: '0px',
                borderTop: '1px solid #CED6D7',
              }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header">
                Gestão de Contratos, Fornecedores e Distribuidoras
              </AccordionSummary>
              <AccordionDetails>
                <div className="space-y-3">
                  <div className="w-full flex justify-start items-center pl-4 gap-3">
                    <p className="text-slate-800">•</p>
                    <p className="text-slate-800">
                      Analisa seus contratos com distribuidoras de energia
                    </p>
                  </div>
                  <div className="w-full flex justify-start items-center pl-4 gap-3">
                    <p className="text-slate-800">•</p>
                    <p className="text-slate-800">
                      Garante que sua empresa obtenha tarifas competitivas e
                      condições favoráveis
                    </p>
                  </div>
                  <div className="w-full flex justify-start items-center pl-4 gap-3">
                    <p className="text-slate-800">•</p>
                    <p className="text-slate-800">
                      Resolve todas as questões que sua empresa tiver junto a
                      concessionária
                    </p>
                  </div>
                  <div className="w-full flex justify-start items-center pl-4 gap-3">
                    <p className="text-slate-800">•</p>
                    <p className="text-slate-800">
                      Faz ajustes que acarretam na economia de sua fatura e
                      eliminação de prejuízos
                    </p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              variant="outlined"
              sx={{
                border: 'none',
                borderRadius: '0px',
                borderTop: '1px solid #CED6D7',
              }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header">
                Implementação de Medidas de Eficiência Energética
              </AccordionSummary>
              <AccordionDetails>
                <div className="space-y-3">
                  <div className="w-full flex justify-start items-center pl-4 gap-3">
                    <p className="text-slate-800">•</p>
                    <p className="text-slate-800">
                      Desenvolve e implementa estratégias para reduzir o consumo
                      de energia
                    </p>
                  </div>
                  <div className="w-full flex justify-start items-center pl-4 gap-3">
                    <p className="text-slate-800">•</p>
                    <p className="text-slate-800">
                      Desenvolve tecnologias e práticas de eficiência energética
                    </p>
                  </div>
                  <div className="w-full flex justify-start items-center pl-4 gap-3">
                    <p className="text-slate-800">•</p>
                    <p className="text-slate-800">
                      Promove uma cultura de eficiência energética dentro da sua
                      organização
                    </p>
                  </div>
                  <div className="w-full flex justify-start items-center pl-4 gap-3">
                    <p className="text-slate-800">•</p>
                    <p className="text-slate-800">
                      Educa seus funcionários sobre práticas de economia de
                      energia
                    </p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              variant="outlined"
              sx={{
                border: 'none',
                borderRadius: '0px',
                borderTop: '1px solid #CED6D7',
              }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header">
                Conformidade com Regulamentações
              </AccordionSummary>
              <AccordionDetails>
                <div className="space-y-3">
                  <div className="w-full flex justify-start items-center pl-4 gap-3">
                    <p className="text-slate-800">•</p>
                    <p className="text-slate-800">
                      Garante que a sua empresa esteja dentro de todas as
                      regulamentações e normas energéticas
                    </p>
                  </div>
                  <div className="w-full flex justify-start items-center pl-4 gap-3">
                    <p className="text-slate-800">•</p>
                    <p className="text-slate-800">
                      Mantém a sua empresa atualizada sobre mudanças nas
                      políticas energéticas e legislações
                    </p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </>
  )
}
