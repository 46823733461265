import React from 'react'
import Etapas from '../../home/components/Etapas'

export default function EtapasEstrategicas() {
  return (
    <div className="flex w-full max-w-7xl px-4 xl:px-0 lg:py-16 pb-0 pt-16 justify-center items-center mx-auto">
      <div>
        <div className="w-full flex flex-col justify-center items-center">
          <h3 className="w-full max-w-[38.5rem] text-center text-slate-800 lg:text-[2.125rem] text-[1.425rem] font-normal">
            Entenda as 3 etapas estratégicas da consultoria Amee
          </h3>
          <p className="w-full max-w-[537px] text-center text-slate-800 text-lg font-normal mt-5">
            Tornamos sua gestão de energia mais inteligente, da captura de dados
            até a otimização de contratos - garantindo o melhor custo para sua
            operação.
          </p>
        </div>
        <div>
          <img
            src="/assets/img/etapas_estrategicas_imagem.png"
            alt="Etapas estratégicas"
            className="hidden lg:flex"
          />
          <img
            src="/assets/img/etapas_estrategicas_imagem_mobile.png"
            alt="Etapas estratégicas"
            className="flex lg:hidden mt-8"
          />
          <Etapas />
        </div>
      </div>
    </div>
  )
}
