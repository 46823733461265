export default function middlewareAxios(axios) {
  axios.interceptors.request.use(
    (config) => {
      config.headers['Access-Control-Allow-Origin'] = '*'
      config.headers['Cache-Control'] = 'no-cache'
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded'

      return config
    },
    (err) => {
      Promise.reject(err)
    }
  )
}
