import React from 'react'
import MelhoresPropostasCard from '../../../../../components/ui/MelhoresPropostasCard'

export default function MelhoresOportunidades() {
  return (
    <div
      className="w-full min-h-[28rem] z-20"
      style={{
        background: 'linear-gradient(90deg, #642CED 0%, #00E7FF 100%)',
      }}>
      <div className="w-full max-w-7xl flex lg:flex-row flex-col items-center justify-around gap-8 px-4 xl:px-0 mx-auto">
        <div className="lg:py-24 pt-24 pb-2">
          <h4 className="w-full max-w-[672px] text-white text-[2.125rem] font-normal leading-[45.90px]">
            Oferecemos as melhores oportunidades de economia e eficiência
            energética para sua empresa <br />
          </h4>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-10">
            <div className="space-y-6 max-w-[20.25rem]">
              <MelhoresPropostasCard
                title="Captura automatizada da fatura"
                imagePath="/assets/img/melhores_oportunidades_image_1.svg"
              />
              <MelhoresPropostasCard
                title="Preparação para pagamento no seu sistema ERP em 72h antes do vencimento"
                imagePath="/assets/img/melhores_oportunidades_image_4.svg"
              />
              <MelhoresPropostasCard
                title="Planejamento energético e otimização da performance contratual"
                imagePath="/assets/img/melhores_oportunidades_image_2.svg"
              />
            </div>
            <div className="space-y-6 max-w-[20.25rem]">
              <MelhoresPropostasCard
                title="Oportunidade de otimização do seu fluxo de caixa"
                imagePath="/assets/img/melhores_oportunidades_image_5.svg"
              />
              <MelhoresPropostasCard
                title="Ressarcimento de cobranças indevidas pelas distribuidora de energia"
                imagePath="/assets/img/melhores_oportunidades_image_3.svg"
              />
              <MelhoresPropostasCard
                title="Garantia do pagamento do mínimo possível em contas de energia"
                imagePath="/assets/img/melhores_oportunidades_image_6.svg"
              />
            </div>
          </div>
        </div>
        <div className="min-h-full -mt-5 max-lg:hidden">
          <img
            src="/assets/img/oportunidades_people.svg"
            alt="Oportunidades_people_illustration"
            className="h-[45rem] -mt-[3.9rem]"
          />
        </div>
      </div>
      <div className="min-h-full max-md:flex hidden">
        <img
          src="/assets/img/oportunidades_people.svg"
          alt="Oportunidades_people_illustration"
        />
      </div>
    </div>
  )
}
