import React, { useCallback, useState, useRef } from 'react'

import 'react-toastify/dist/ReactToastify.css'

import { PlusIcon } from '@heroicons/react/outline'
import { useNavigate } from 'react-router-dom'
import { Field, Form, Formik } from 'formik'
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'
import * as yup from 'yup'

import { incluirLeadGeneric } from '../../../../../pages/api/simulacaoLead'
import { getUploadId, uploadArquivo } from '../../../../../service/Api'
import { validarCPFCNPJ } from '../../../../../utils/validarCPFCNPJ'
import { CNPJ, Telefone } from '../../../../../components/control'
import { Input } from '../../../../../components/control/Input'

export default function FormHero({ formPasso, setDadosEnviados }) {
  const navigate = useNavigate()

  const [arquivosSelecionados, setArquivosSelecionados] = useState(null)
  const [aceitoTermos, setAceitoTermos] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const formikRef = useRef(null)

  const notify = () => {
    toast.warn('Preencha todos os campos!', {
      style: {
        fontSize: '0.815rem',
      },
    })
  }
  const notifyErro = () => {
    toast.error('Algo deu errado. Tente novamente!', {
      style: {
        fontSize: '0.815rem',
      },
    })
  }
  const notifyMaxFile = () => {
    toast.warn('Envie apenas uma fatura', {
      style: {
        fontSize: '0.815rem',
      },
    })
  }
  const notifySucesso = () => {
    toast.success('Dados enviados com sucesso!', {
      style: {
        fontSize: '0.815rem',
      },
    })
  }

  const formShemaValidation = yup.object({
    name: yup.string(),
    email: yup.string().email('E-mail inválido'),
    telefone: yup.string(),
    empresa: yup.string(),
    cnpj: yup.string(),
    cargo: yup.string(),
    mercadoLivre: yup.string(),
    geracaoDistribuida: yup.string(),
    realizadorgestao: yup.string(),
    contaEnergia: yup.array(),
    file: yup.string(),
  })

  const { getRootProps, getInputProps, ...rest } = useDropzone({
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 1) {
        notifyMaxFile()
        return
      }
      setArquivosSelecionados(acceptedFiles)
    },
    accept: {
      'application/pdf': ['.pdf'],
    },
  })

  const saveDemanda = useCallback(
    async (form) => {
      setIsLoading(true)
      const novasStrings = []
      var validador = 0
      if (arquivosSelecionados && arquivosSelecionados.length > 0) {
        arquivosSelecionados.forEach((element) => {
          setIsLoading(true)
          let reader = new FileReader()
          reader.readAsDataURL(element)
          reader.onloadend = (e) => {
            uploadArquivo(e.target.result).then(function (e) {
              novasStrings.push({
                urlFile: e.data.entities.url,
                name: element.name,
              })
              validador = validador + 1
              if (validador == arquivosSelecionados.length) {
                const newForm = {
                  ...form,
                  file: novasStrings,
                  idTipoProduto: '11',
                  cnpj: form.cnpj.replace(/\D/g, ''),
                  telefone: form.telefone.replace(/\D/g, ''),
                }
                localStorage.setItem(
                  '@dados_proposta_amee',
                  JSON.stringify(newForm)
                )
                incluirLeadGeneric(newForm)
                  .then(async (res) => {
                    localStorage.setItem('@hash__accept', res.outrosValores)
                    await getUploadId({
                      navigate,
                      notifySucesso,
                      setIsLoading,
                      resetForm: formikRef.current.resetForm,
                      clearFiles: setArquivosSelecionados,
                      file: arquivosSelecionados,
                    })
                  })
                  .catch(() => {
                    setIsLoading(false)
                  })
              }
            })
          }
        })
      } else {
        const newForm = {
          ...form,
          file: null,
          idTipoProduto: '11',
          cnpj: form.cnpj.replace(/\D/g, ''),
          telefone: form.telefone.replace(/\D/g, ''),
        }
        incluirLeadGeneric(newForm)
          .then(async () => {
            setIsLoading(false)
            notifySucesso()
            window.scrollTo(0, 0)
            navigate('/proposta-nao-enviada')
          })
          .catch(() => {
            setIsLoading(false)
            notifyErro()
          })
      }
    },
    [
      arquivosSelecionados,
      setDadosEnviados,
      setIsLoading,
      setArquivosSelecionados,
      notifySucesso,
      notifyErro,
      formikRef,
    ]
  )

  var formulario = {
    name: '',
    email: '',
    telefone: '',
    empresa: '',
    cnpj: '',
    cargo: '',
    mercadoLivre: '',
    geracaoDistribuida: '',
    realizadorgestao: '',
    contaEnergia: [],
    file: '',
  }

  return (
    <>
      <Formik
        innerRef={formikRef}
        enableReinitialize={true}
        initialValues={formulario}
        validationSchema={formShemaValidation}
        onSubmit={async (values) => {
          if (
            values.name == '' ||
            values.email == '' ||
            values.telefone == '' ||
            values.empresa == '' ||
            values.cargo == '' ||
            values.cnpj == ''
          ) {
            notify()
            return
          }
          // else if (!validarCPFCNPJ(values.cnpj)) {
          //   toast.error('CNPJ inválido', {
          //     style: {
          //       fontSize: '0.815rem',
          //     },
          //   })
          //   return
          // }
          // else {
          await saveDemanda(values)
          // }
        }}>
        {({ handleSubmit, setFieldValue, values, errors, isSubmitting }) => (
          <>
            <Form
              className="rounded-2xl py-8 px-10 min-h-[606px] w-full max-w-[32.875rem]"
              style={{
                backgroundColor: 'rgba( 255, 255, 255, 0.35 )',
                backdropFilter: 'blur(10px)',
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
              }}>
              <p className="mb-8 mt-2 w-full text-center text-[#142a42] lg:text-lg text-base font-normal font-['Plus Jakarta Sans'] leading-normal">
                Preencha seus dados e tenha um
                <br />
                diagnóstico em menos de 1 minuto!
              </p>
              <div
                className={`${
                  formPasso == 1 ? 'flex' : 'hidden'
                } flex-col space-y-2`}>
                <div>
                  <label
                    htmlFor="name"
                    className="form-label text-slate-800 text-base font-normal leading-normal">
                    Nome completo
                    <span>*</span>
                  </label>
                  <Field
                    component={Input}
                    disabled={isLoading}
                    name="name"
                    onChange={(e) => {
                      setFieldValue('name', e.target.value)
                      localStorage.setItem('proposta_nome', e.target.value)
                    }}
                    className="form-control rounded-lg border border-gray-300"
                    placeholder="Nome completo"
                  />
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="form-label text-slate-800 text-base font-normal leading-normal">
                    E-mail corporativo<span>*</span>
                  </label>
                  <Field
                    disabled={isLoading}
                    component={Input}
                    name="email"
                    className="form-control rounded-lg border border-gray-300"
                    placeholder="E-mail corporativo"
                  />
                </div>
                <div>
                  <label
                    htmlFor="cargo"
                    className="form-label text-slate-800 text-base font-normal leading-normal">
                    Cargo<span>*</span>
                  </label>
                  <Field
                    disabled={isLoading}
                    component={Input}
                    name="cargo"
                    className="form-control rounded-lg border border-gray-300"
                    placeholder="Seu cargo na empresa"
                  />
                </div>
                <div>
                  <label htmlFor="telefone" className="form-label">
                    Telefone<span>*</span>
                  </label>
                  <Field
                    disabled={isLoading}
                    component={Telefone}
                    name="telefone"
                    className="form-control rounded-lg border border-gray-300"
                    placeholder="(55)99999-9999"
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <label
                    htmlFor="cnpj"
                    className="form-label text-slate-800 text-base font-normal leading-normal">
                    CNPJ<span>*</span>
                  </label>
                  <Field
                    disabled={isLoading}
                    component={CNPJ}
                    name="cnpj"
                    className="form-control rounded-lg border border-gray-300"
                    placeholder="CNPJ"
                  />
                </div>
                <div>
                  <label
                    htmlFor="empresa"
                    className="form-label text-slate-800 text-base font-normal leading-normal">
                    Empresa<span>*</span>
                  </label>
                  <Field
                    disabled={isLoading}
                    component={Input}
                    name="empresa"
                    className="form-control rounded-lg border border-gray-300"
                    placeholder="Nome da empresa"
                  />
                </div>
                <>
                  <div className="text-slate-800 text-base font-normal font-['Plus Jakarta Sans'] leading-normal">
                    Conta de energia
                  </div>
                  <label
                    {...getRootProps()}
                    {...rest}
                    className={`${
                      arquivosSelecionados != null &&
                      'flex h-[9.063rem] flex-col justify-center items-center w-full bg-gray-50 rounded-lg border-2 border-blue-500 border-dashed cursor-pointer mt-2 mb-5'
                    }${
                      arquivosSelecionados == null &&
                      'flex h-[9.063rem] flex-col justify-center items-center w-full bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer mt-2 mb-5'
                    }`}
                    htmlFor="importacaoCSV">
                    <div className="flex flex-col justify-center items-center pt-5 pb-6">
                      <svg
                        className={
                          arquivosSelecionados != null
                            ? 'mb-2 w-8 h-8 text-blue-500'
                            : 'mb-2 w-8 h-8 text-gray-400'
                        }
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                      </svg>
                      <p
                        className={
                          arquivosSelecionados != null
                            ? 'mb-2 text-sm text-blue-500 font-semibold text-center'
                            : 'text-sm text-gray-500 dark:text-gray-400 font-semibold text-center'
                        }>
                        {' '}
                        {!arquivosSelecionados ? (
                          <div className="flex flex-col justify-center items-center">
                            <p className="lg:w-[357px] w-fit text-center text-gray-400 text-sm font-medium leading-[18.90px]">
                              Anexar conta de energia em PDF
                            </p>
                            <div className="w-8 h-8 p-1.5 bg-cyan-400 rounded justify-center items-center gap-2.5 flex mt-3">
                              <PlusIcon className="text-white font-semibold" />
                            </div>
                          </div>
                        ) : (
                          <p>{`${arquivosSelecionados.length} arquivo(s) selecionado(s)`}</p>
                        )}
                      </p>
                    </div>
                    <input
                      {...getInputProps({
                        accept: '.pdf',
                        name: 'contaEnergia',
                        disabled: isLoading,
                      })}
                    />
                  </label>
                </>
                <label
                  htmlFor="aceitoTermos"
                  className="flex justify-start items-center gap-2 pt-4">
                  <input
                    id="aceitoTermos"
                    disabled={isLoading}
                    value={aceitoTermos}
                    onChange={() => setAceitoTermos(!aceitoTermos)}
                    type="checkbox"
                    className="w-5 h-5 border border-gray-300 rounded-md outline-none ring-0"
                  />
                  <div className="lg:w-[359.55px] w-fit text-slate-600 lg:text-sm text-xs font-medium leading-[18.90px]">
                    Aceito os termos do contrato
                  </div>
                </label>
              </div>
              <div className="w-full flex justify-center items-center mt-4">
                <button
                  disabled={!aceitoTermos || isLoading}
                  className="w-fit mt-6 h-11 px-8 lg:py-3 py-1 bg-cyan-400 hover:bg-cyan-200 transition ease-in-out duration-200 rounded-full border border-cyan-400 justify-center items-center flex disabled:opacity-50 disabled:cursor-not-allowed"
                  onClick={(e) => {
                    if (isLoading) return
                    e.preventDefault()
                    if (
                      (formPasso == 1 && values.name == '') ||
                      values.email == '' ||
                      values.telefone == '' ||
                      values.empresa == '' ||
                      values.cnpj == '' ||
                      values.cargo == ''
                    ) {
                      notify()
                      return
                    }
                    handleSubmit()
                  }}>
                  <div className="text-center text-slate-800 lg:text-sm text-xs font-medium leading-tight">
                    {isLoading ? 'Carregando...' : 'Quero fazer um diagnóstico'}
                  </div>
                </button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  )
}
