import React from 'react'
import { FieldFeedbackLabel } from './FieldFeedbackLabel'

const getFieldCSSClasses = (touched, errors) => {
  const classes = ['']
  if (touched && errors) {
    classes.push('border-b border-b-red-500 text-red-500 text-sm')
  }

  if (touched && !errors) {
    classes.push('border-b border-b-green-500 text-green-500 text-sm')
  }

  return classes.join(' ')
}

export function Input({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = 'text',
  ...props
}) {
  return (
    <>
      <div className="flex flex-col py-2">
        {label && (
          <label className="text-base font-medium text-zinc-700">{label}</label>
        )}
        <input
          type={type}
          className={`appearance-none bg-transparent border-none w-full text-base text-gray-700 py-1 leading-tigh border-transparent focus:border-transparent focus:ring-0 px-0 h-12  ${getFieldCSSClasses(
            touched[field.name],
            errors[field.name]
          )}`}
          {...field}
          {...props}
        />
      </div>
      {withFeedbackLabel && errors[field.name] && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          className={`${getFieldCSSClasses(
            touched[field.name],
            errors[field.name]
          )}`}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  )
}
