import { config } from '../../src/Config'
import axios from 'axios'
import * as fs from 'fs'

const api = axios.create()

export const baseURL = config.url.urlUpload
export const apiURL = config.url.apiEndPoint
const curlBaseURL = 'https://api-queue-control.amee.com.br'
const curlToken = '03c61355-05dc-49fa-828d-c66954623aa3'

export const uploadArquivo = (arquivo) => {
  return new Promise(async (resolve) => {
    if (arquivo == null || arquivo == '') {
      resolve(null)
    }
    var data = { arquivo: arquivo }

    var _config = {
      method: 'post',
      url: baseURL + `/uploadFileAnexo/rzk`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }

    api(_config)
      .then((response) => resolve(response))
      .catch(() => resolve(null))
  })
}

/**
 * @description Pega o id do upload do arquivo e a url para fazer o upload
 */
export const getUploadId = async ({
  navigate,
  notifySucesso,
  setIsLoading,
  resetForm,
  clearFiles,
  file,
}) => {
  let config = {
    method: 'POST',
    url: `${curlBaseURL}/upload`,
    headers: {
      Token: curlToken,
    },
  }

  try {
    const req = await fetch(config.url, {
      method: 'POST',
      headers: {
        Token: curlToken,
      },
    })

    const result = await req.json()
    if (result && result.guid && result.url) {
      localStorage.setItem('upload_guid', result.guid)
      localStorage.setItem('upload_url', result.url)

      await uploadFile(result.url, file, result.host, navigate)
        .then((res) => {
          notifySucesso()
          setIsLoading(false)
          navigate('/redirect/proposta')
          resetForm()
          clearFiles()
        })
        .catch(() => {
          window.scrollTo(0, 0)
          navigate('/proposta-nao-enviada')
        })
    } else {
      window.scrollTo(0, 0)
      navigate('/proposta-nao-enviada')
    }
  } catch (e) {
    window.scrollTo(0, 0)
    navigate('/proposta-nao-enviada')
  }
}

/**
 * @description Faz o upload de um arquivo para o servidor
 */
export const uploadFile = async (url, file, host, navigate) => {
  const reader = new FileReader()
  reader.readAsArrayBuffer(file[0])
  reader.onload = async () => {
    try {
      const res = await fetch(url, {
        method: 'PUT',
        redirect: 'follow',
        headers: {
          'Content-Length': file[0].size,
          Accept: '*/*',
          Host: host,
        },
        body: reader.result,
      })
      return true
    } catch (e) {
      window.scrollTo(0, 0)
      navigate('/proposta-nao-enviada')
      return false
    }
  }
  return false
}

/**
 * @description Pega o status do upload do arquivo passando o id. Caso demore
 */
export const getStatuUpload = async () => {
  const id = localStorage.getItem('upload_guid')

  const config = {
    method: 'GET',
    url: `${curlBaseURL}/status/${id}`,
    headers: {
      Token: curlToken,
    },
  }
  const req = await fetch(config.url, {
    method: 'GET',
    headers: {
      Token: curlToken,
    },
  })
  return req.json()
}

/**
 * @description Pega os dados caso o status do upload seja: "status": "callback_finished"
 */
export const getResultadoProposta = async () => {
  const id = localStorage.getItem('upload_guid')

  let config = {
    method: 'GET',
    url: `${curlBaseURL}/get/${id}`,
    headers: {
      Token: curlToken,
    },
  }

  const req = await fetch(config.url, {
    method: 'GET',
    headers: {
      Token: curlToken,
    },
  })
  return req.json()
}

export const aceitarProposta = async () => {
  const token = localStorage.getItem('@hash__accept')
  if (!token) return
  const config = {
    method: 'PUT',
    url: `${apiURL}/lead/proposta/aceitar?token=${token}`,
  }
  try {
    const req = await fetch(config.url, {
      method: config.method,
    })
    return req.json()
  } catch (e) {
    throw new Error(e)
  }
}
