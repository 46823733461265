import React from 'react'

import ReactPlayer from 'react-player'
import { useTheme, useMediaQuery } from '@mui/material'

export default function PropostaEnviadaVideo() {
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <div
      className="bg-white flex flex-col justify-center items-center w-full lg:h-[34.063rem] h-[26.125rem] lg:py-8 py-4 px-6 relative space-y-5 lg:mb-20"
      style={{
        backgroundImage: 'url(/assets/img/fundo_amee_cores.png)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}>
      <p className="text-center text-[#142a42] text-lg font-medium font-['Plus Jakarta Sans'] leading-[25px]">
        Entenda como funciona o diagnóstico da Amee:{' '}
      </p>
      <ReactPlayer
        url="https://www.youtube.com/watch?v=nlbNjxPdJEc"
        width={smDown ? '100%' : 760}
        height={smDown ? 290 : 600}
        className="shadow-2xl rounded"
        config={{
          youtube: {
            playerVars: { showinfo: 1 },
          },
        }}
      />
    </div>
  )
}
