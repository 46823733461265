import React from 'react'

import { XIcon } from '@heroicons/react/outline'
import { Modal } from 'react-bootstrap'
import Lottie from 'lottie-react'

import OfertaEnviada from '../../../../../assets/lotties/oferta_enviada_lottie.json'

export default function OfertaEnviadaModal({ open, setOpen }) {
  return (
    <div
      className={`${!open ? 'hidden' : '-mr-2'} relative z-[9999999]`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true">
      <div className="fixed inset-0 bg-gray-500 bg-neutral-900/80" />
      <div className="fixed inset-0 overflow-hidden">
        <div className="flex items-center sm:items-center justify-center min-h-full p-6 text-center sm:p-0">
          <div className="flex justify-start items-start gap-6 relative bg-white rounded-lg px-4 pt-2 pb-4 text-left shadow-xl sm:my-0.5 sm:p-6 sm:pb-0.5 lg:min-w-[27.25rem] 2xl:min-w-[31.25rem]">
            <div className="flex flex-col items-start justify-start w-full h-full">
              <Modal.Header className="mb-4 w-full">
                <Modal.Title id="example-modal-sizes-title-lg">
                  <div>
                    <div className="flex flex-col items-start justify-start gap-1">
                      <div className="text-neutral-800text-lg font-medium">
                        Oferta Enviada
                      </div>
                    </div>

                    <XIcon
                      className="text-gray-800 hover:text-gray-700 cursor-pointer w-4 h-4 absolute top-3 right-3 transition-all duration-200"
                      size={12}
                      onClick={() => {
                        setOpen(false)
                      }}
                    />
                  </div>
                </Modal.Title>
              </Modal.Header>
              <div
                className={`h-0.5 w-full border-t border-neutral-200/50" mb-1`}
              />
              <div className="flex-col flex items-center justify-center w-full 2xl:max-h-[80vh] max-h-[70vh] overflow-y-auto overflow-x-hidden px-2 py-5">
                <p className="text-gray-800 text-center w-full flex flex-col justify-center items-center font-['Plus Jakarta Sans'] gap-5 lg:text-xl text-base font-medium">
                  Enviamos uma oferta exclusiva para o seu e-mail!
                </p>
                <Lottie
                  animationData={OfertaEnviada}
                  style={{
                    width: 170,
                    height: 170,
                  }}
                />
                <button
                  onClick={() => setOpen(false)}
                  className="w-fit my-4 h-11 px-8 lg:py-3 py-1 bg-cyan-400 hover:bg-cyan-200 transition ease-in-out duration-200 rounded-full border border-cyan-400 justify-center items-center flex disabled:opacity-50 disabled:cursor-not-allowed">
                  Entendi
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
