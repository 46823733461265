import React from 'react'
export default function FooterAdesao() {
  return (
    <footer>
      <div className="bg-[#333333] px-8 md:px-20 py-8 md:py-12">
        <img src="/logo/logo-default.svg" className="md:w-1/6" alt="Logo TIM" />
      </div>
      <div className="bg-primary px-8 md:px-20 py-5">
        <p className="font-normal text-sm text-white">
          Criado e desenvolvido por Acesso Energia
        </p>
      </div>
    </footer>
  )
}
