import React, { useEffect, useState } from 'react'

export default function Header() {
  const [scrollRolou, setScrollRolou] = useState(false)

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 0) {
        setScrollRolou(true)
      } else {
        setScrollRolou(false)
      }
    }
    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return (
    <>
      <header
        className={`w-full lg:fixed lg:top-0 z-50 transition-all duration-500 ease-in-out`}
        style={{
          backgroundColor: scrollRolou
            ? 'rgba( 255, 255, 255, 0.35 )'
            : 'transparent',
          backdropFilter: scrollRolou ? 'blur(10px)' : 'none',
          boxShadow: scrollRolou ? '0 0 10px rgba(0, 0, 0, 0.1)' : 'none',
        }}>
        <nav className="w-full flex justify-center items-center">
          <div className="flex w-full max-w-7xl px-4 xl:px-0 h-20 py-5 justify-center items-center">
            <div className="w-full flex justify-start items-center flex-[1] gap-11">
              <a href="/#">
                <img src="/assets/img/logotipo.svg" alt="Logotipo Amee" />
              </a>
              <a href="/#">
                <img
                  src="/assets/img/vivo_logo.svg"
                  alt="Logotipo Vivo Valoriza"
                />
              </a>
            </div>
          </div>
        </nav>
      </header>
    </>
  )
}
