import React from 'react'

import ProstaEnviadaInfos from './components/ProstaEnviadaInfos'
import Footer from '../../../../layout/components/Footer'
import Header from '../../../../layout/components/Header'

export default function PropostaEnviada() {
  return (
    <div>
      <div>
        <Header />
      </div>
      <section className="mb-14">
        <ProstaEnviadaInfos />
      </section>
      <Footer />
    </div>
  )
}
