import React from 'react'

export default function Footer() {
  return (
    <footer className="w-full relative max-w-7xl mx-auto px-4 xl:px-0 pb-11 pt-10 space-y-11">
      <div className="w-full flex lg:flex-row flex-col justify-center items-center gap-5">
        <a href="/">
          <img src="/assets/img/logotipo.svg" alt="Logotipo Amee" />
        </a>
        <a href="/">
          <img src="/assets/img/vivo_logo.svg" alt="Logotipo Vivo Valoriza" />
        </a>
      </div>
      <div className="w-full flex lg:flex-row flex-col justify-between items-center gap-4">
        <p className="text-slate-800 text-sm font-medium leading-[18.90px]">
          {`Todos os direitos reservados Amee © ${new Date().getFullYear()}`}
        </p>
        <a
          href="#"
          className="text-right text-slate-800 text-sm font-medium underline leading-[18.90px]">
          Política de privacidade
        </a>
      </div>
    </footer>
  )
}
