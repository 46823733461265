//Importação React
import axios from 'axios'
import { Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { config } from '../../../../../Config'
import FooterAdesao from '../../../../../components/adesao/adesao_footer'
import AdesaoHeader from '../../../../../components/adesao/adesao_header'
import { Input } from '../../../../../components/control/Input'
import numeral from '../../../../../components/control/numeralBRL'
import ModalSolarCocaCola from '../../../../../components/modal/Modal'
import ModalTermos from '../../../../../components/modal/ModalTermos'
import {
  alterarSimulacaoLeadUnificado,
  alterarSimulacaoLeadUnificadoHash,
  uploadArquivoTeste,
} from '../../../../../pages/api/simulacaoLead'
import { EnviarInscricao, Finalizar, Voltar } from '../../../../../store'
import '../cadastro.css'
import EconomiaTotal from '../components/EconomiaTotal'
import styles from './adesao_formulario_documentos.module.css'
export const baseURL = config.url.urlUpload
const api = axios.create()

const AdesaoFormDocSchema = Yup.object().shape({
  // faturaEnergia: Yup.string().required('Fatura é obrigatório'),
  // strParametro5: Yup.string().required('Login é obrigatório'),
  // strParametro6: Yup.string().required('Senha é obrigatório'),
  termoAdesao: Yup.bool().oneOf(
    [true],
    'Accept Terms & Conditions is required'
  ),
})

export default function Cadastro2() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const fluxoConsorcio = useSelector((state) => state)
  const idLead = fluxoConsorcio.consorciosCocaCola.idLead
  const [arquivoCartaoCNPJ, setArquivoCartaoCNPJ] = useState(null)
  const [arquivoFaturaEnergia, setArquivoFaturaEnergia] = useState(null)
  const [labelArquivoFaturaEnergia, setLabelArquivoFaturaEnergia] = useState(
    'Selecione o arquivo da Fatura de Energia'
  )
  const [mudarArquivo, setMudarArquivo] = useState(false)
  const [controlarMensagem, setControlarMensagem] = useState(false)
  // const [Extensao, setExtensao] = useState(false)
  const [mensagemAlerta, setMensagemAlerta] = useState(false)
  const [mensagemAlertaCheck, setMensagemAlertaCheck] = useState(false)

  const { hash } = useParams()

  // const fileType = ['application/pdf']

  const extensoesPermitidas = ['.pdf']

  // onchange event
  // const fileType = ['application/pdf'];
  const uploadOnChangeArquivo = (e, tipoArquivo) => {
    let selectedFile = e.target.files[0]
    // && fileType.includes(selectedFile.type)
    if (selectedFile) {
      setMensagemAlerta(false)
      // setExtensao(selectedFile.name.substring(selectedFile.name.indexOf('.')))
      let reader = new FileReader()
      reader.readAsDataURL(selectedFile)
      reader.onloadend = (e) => {
        setArquivoFaturaEnergia(e.target.result)
        setLabelArquivoFaturaEnergia(selectedFile.name)
      }
    } else {
      setMensagemAlerta(true)
    }
  }
  const uploadArquivo = (arquivo, tipoArquivo) => {
    return new Promise(async (resolve) => {
      var axios = require('axios')
      if (arquivo == null || arquivo == '') {
        resolve(null)
      }
      var data = { arquivo: arquivo }

      var _config = {
        method: 'post',
        url: baseURL + `/uploadFileAnexo/rzk`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      }

      api(_config)
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          resolve(null)
        })
    })
  }

  const [valor1Mes, setValor1Mes] = useState(0)
  const [termos, setTermos] = useState(false)
  const [politica, setPolitica] = useState(false)
  const [valor12Mes, setValor12Mes] = useState(0)
  const [valorSemDesconto, setValorSemDesconto] = useState(0)
  const [idLeadLocal, setIdLead] = useState(0)

  useEffect(() => {
    var valormes1 = localStorage.getItem('valor1Mes')
    var valormes12 = localStorage.getItem('valor12Mes')
    var valorSemDesconto = localStorage.getItem('valorDesconto')
    var idLead = localStorage.getItem('idLeadTela')

    var formatado1 = parseFloat(valormes1)
    var formatado2 = parseFloat(valormes12)
    var formatado3 = parseFloat(valorSemDesconto)
    var formatado4 = parseInt(idLead)

    // const wizard = localStorage.getItem('wizard')
    // const objWizard = JSON.parse(wizard)
    // // wizard !== "true"
    // if (objWizard != null) {
    //   if (objWizard.ver !== true || objWizard.pagina !== 2) {
    //     window.location.href = 'http://localhost:3000/'
    //   }
    // } else {
    //   window.location.href = 'http://localhost:3000/'
    // }

    setValor1Mes(formatado1)
    setValor12Mes(formatado2)
    setValorSemDesconto(formatado3)
    setIdLead(formatado4)
  }, [])

  const voltar = () => {
    localStorage.removeItem('wizard')
    localStorage.setItem('wizard', JSON.stringify({ ver: true, pagina: 1 }))
    navigate('/adesao/dados-consorcio')
  }

  return (
    <>
      <Formik
        initialValues={{
          idLead: '',
          strParametro5: '',
          strParametro6: '',
          faturaEnergia: '',
          termoAdesao: false,
        }}
        validationSchema={AdesaoFormDocSchema}
        onSubmit={(values) => {
          if (arquivoFaturaEnergia == null) {
            setMensagemAlerta(true)
            // alterarSimulacaoLead(
            //   idLeadLocal,
            //   values.strParametro5,
            //   values.strParametro6,
            //   null,
            //   null,
            //   null
            // ).then(function (response) {
            //   dispatch(
            //     EnviarInscricao({
            //       idLead: idLeadLocal,
            //     })
            //   )
            //   localStorage.removeItem('wizard')
            //   localStorage.setItem('wizard', JSON.stringify({ver: true, pagina: 3}))
            //   navigate('/adesao/termos')
            // })
          } else {
            console.log(hash != 'null')

            if (mensagemAlerta == true) {
              setMensagemAlerta(false)
            }
            uploadArquivo(arquivoFaturaEnergia).then(function (retornoUpload2) {
              let faturaPath
              let faturaURL
              if (
                retornoUpload2 != null &&
                retornoUpload2.data != null &&
                retornoUpload2.data.entities != null
              ) {
                faturaPath = retornoUpload2.data.entities.path
                faturaURL = retornoUpload2.data.entities.url
              }
              var nomeArquivo = labelArquivoFaturaEnergia
              if (values.strParametro5 != '') {
                values.strParametro5 = String(values.strParametro5)
              } else {
                values.strParametro5 = null
              }
              if (values.strParametro6 == '') {
                values.strParametro6 = null
              }
              // if (extensoesPermitidas.includes(Extensao)) {
              if (hash != 'null') {
                alterarSimulacaoLeadUnificadoHash(
                  hash,
                  faturaURL,
                  faturaPath,
                  nomeArquivo,
                  'V'
                ).then(function (response) {
                  // dispatch(
                  //   EnviarInscricao({
                  //     idLead: idLeadLocal,
                  //   })
                  // )
                  localStorage.removeItem('wizard')
                  localStorage.setItem(
                    'wizard',
                    JSON.stringify({ ver: true, pagina: 3 })
                  )
                  navigate('/adesao/obrigado')
                })
              } else {
                alterarSimulacaoLeadUnificado(
                  idLeadLocal,
                  values.strParametro5,
                  values.strParametro6,
                  faturaURL,
                  faturaPath,
                  nomeArquivo,
                  'V'
                ).then(function (response) {
                  dispatch(
                    EnviarInscricao({
                      idLead: idLeadLocal,
                    })
                  )
                  localStorage.removeItem('wizard')
                  localStorage.setItem(
                    'wizard',
                    JSON.stringify({ ver: true, pagina: 3 })
                  )
                  navigate('/adesao/obrigado')
                })
              }
              // }
            })
          }
        }}>
        {({ handleSubmit, errors, setFieldValue }) => (
          <>
            {termos && (
              <ModalTermos
                onClose={() => setTermos(false)}
                obj={termos}
                page={'termos'}
              />
            )}
            {politica && (
              <ModalTermos
                onClose={() => setPolitica(false)}
                obj={politica}
                page={'politica'}
              />
            )}

            {/* { && (
            < onClose={() => (false)} obj={obj} ></ModalSolarCocaCola>
          )} */}
            <>
              <AdesaoHeader progress="40%" />
              <main className="bg-[#f0efea] px-5 md:px-14 py-10 md:py-16 pt-28 md:mt-0">
                <section className="grid md:grid-cols-6 items-start gap-5">
                  <div className="md:col-span-4 bg-white drop-shadow-xl p-8 w-full rounded-xl border border-gray-300">
                    <div>
                      <h3 className="font-bold text-2xl md:text-3xl text-zinc-800 mb-10">
                        Preencha os dados da fatura
                      </h3>
                      <Form>
                        <div className="flex items-center justify-between">
                          <div className="mb-2">
                            <h3 className="text-xl text-zinc-700 font-semibold mb-1">
                              Última fatura
                            </h3>
                            <h3 className="text-base text-zinc-600 font-normal">
                              {labelArquivoFaturaEnergia}
                            </h3>
                            {/* {controlarMensagem === false ? (
                            <></>
                          ) : (
                            <span
                              style={{
                                color: '#FA5C7D',
                                fontSize: '12px',
                              }}>
                              O arquivo deve ser: PDF
                            </span>
                          )} */}
                            {mensagemAlerta === false ? (
                              <></>
                            ) : (
                              <span
                                style={{
                                  color: '#FA5C7D',
                                  fontSize: '12px',
                                }}>
                                Selecione uma fatura
                              </span>
                            )}
                          </div>
                          <div>
                            <label
                              htmlFor="faturaEnergia"
                              // style={{
                              //   paddingRight: '55px',
                              //   paddingLeft: '55px',
                              //   paddingTop: '15px',
                              //   paddingBottom: '15px',
                              // }}
                              className="px-10 py-3 bg-secondary text-white font-bold w-fit mt-3 rounded-full hover:bg-[#4e7c14] cursor-pointer"
                              name="faturaEnergia"
                              onChange={(e) => uploadOnChangeArquivo(e)}
                              for="faturaEnergia">
                              {' '}
                              Buscar
                            </label>
                            <input
                              className="hidden"
                              type="file"
                              id="faturaEnergia"
                              onChange={(e) => uploadOnChangeArquivo(e)}
                              // accept=".pdf"
                            />
                          </div>
                        </div>
                        {/* <div className="mb-2">
                        <label
                          htmlFor="strParametro5"
                          className="cadastro_label">
                          Login do Portal da Distribuidora
                        </label>
                        <Field
                          component={Input}
                          name="strParametro5"
                          className="form-control"
                          type='number'
                        />
                      </div>
                      <div className="mb-2">
                        <label
                          htmlFor="strParametro6"
                          className="cadastro_label">
                          Senha do Portal da Distribuidora
                        </label>
                        <Field
                          component={Input}
                          type="password"
                          name="strParametro6"
                          className="form-control"
                        />
                      </div> */}
                        <div className="flex justify-between items-center mt-10">
                          <div className="flex items-center gap-x-2">
                            <Field
                              type="checkbox"
                              name="termoAdesao"
                              Required
                            />
                            <label className="text-zinc-800">
                              &nbsp;Aceito os
                              <a
                                onClick={(e) => setTermos(true)}
                                className="underline">
                                {' '}
                                termos de uso{' '}
                              </a>
                              e
                              <span
                                onClick={(e) => setPolitica(true)}
                                className="underline">
                                {' '}
                                politicas de privacidade
                              </span>
                            </label>
                          </div>
                        </div>

                        <div className="flex justify-between items-center">
                          <button
                            className="px-10 py-3 bg-[#667dad] text-white font-bold w-fit mt-3 rounded-full hover:bg-[#171c8f]"
                            onClick={voltar}>
                            Voltar
                          </button>
                          <a
                            type="button"
                            className="px-7 py-3 bg-primary text-white font-bold w-fit mt-3 rounded-full hover:opacity-90 cursor-pointer"
                            onClick={handleSubmit}
                            // href="/adesao/termos"
                          >
                            Continuar
                          </a>
                        </div>
                      </Form>
                    </div>
                  </div>

                  <div className="md:col-span-2 bg-white drop-shadow-xl p-8 w-full rounded-xl border border-gray-300">
                    <div>
                      <h3 className="text-2xl md:text-3xl font-bold text-zinc-800 mb-6">
                        Economia Atual
                      </h3>
                      <p className="mb-1 text-lg text-zinc-700">
                        Contrato de{' '}
                        <strong className="text-bold">12 meses</strong> economia
                        projetada de:
                      </p>
                      <h3 className="font-bold text-2xl md:text-3xl text-zinc-700">
                        {'R$ ' + numeral(valor12Mes).format('0,0.00')}
                      </h3>
                      <p className="pt-5 pb-1 text-lg text-zinc-700">
                        Benefício
                      </p>
                      <h3 className="font-bold text-2xl md:text-3xl text-zinc-700">
                        15%
                      </h3>
                      <p className="pt-5 pb-1 text-lg text-zinc-700 ">
                        Valor atual da energia
                      </p>
                      <h3 className="font-bold text-2xl md:text-3xl text-zinc-500 line-through">
                        {'R$ ' + numeral(valorSemDesconto).format('0,0.00')}
                      </h3>
                      <p className="pt-5 pb-1 text-lg text-zinc-700">
                        Valor do plano
                      </p>
                      <h3 className="font-bold text-2xl md:text-3xl text-zinc-700">
                        {'R$ ' + numeral(valor1Mes).format('0,0.00')}
                      </h3>
                    </div>
                  </div>
                </section>
              </main>
              <FooterAdesao />
            </>
          </>
        )}
      </Formik>
    </>
  )
}
