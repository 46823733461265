//Importação React
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import numeral from '../../../../../components/control/numeralBRL'
import { EnviarInscricao, Finalizar, Voltar } from '../../../../../store'

import FooterAdesao from '../../../../../components/adesao/adesao_footer'
import AdesaoHeader from '../../../../../components/adesao/adesao_header'
import { Input } from '../../../../../components/control/Input'
import { termoAdesaoSimulacaoLead } from '../../../../../pages/api/simulacaoLead'
import '../cadastro.css'
import EconomiaTotal from '../components/EconomiaTotal'

const AdesaoFormTermoSchema = Yup.object().shape({
  termoAdesao: Yup.bool().oneOf(
    [true],
    'Accept Terms & Conditions is required'
  ),
})

export default function Cadastro3() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const fluxoConsorcio = useSelector((state) => state)

  const idLeadSimulacao = fluxoConsorcio.idLead

  const [checked, setChecked] = useState(false)

  const [valor1Mes, setValor1Mes] = useState(0)
  const [valor12Mes, setValor12Mes] = useState(0)
  const [valorSemDesconto, setValorSemDesconto] = useState(0)
  const [idLeadLocal, setIdLead] = useState(0)

  useEffect(() => {
    var valormes1 = localStorage.getItem('valor1Mes')
    var valormes12 = localStorage.getItem('valor12Mes')
    var valorSemDesconto = localStorage.getItem('valorDesconto')
    var idLead = localStorage.getItem('idLeadTela')

    var formatado1 = parseFloat(valormes1)
    var formatado2 = parseFloat(valormes12)
    var formatado3 = parseFloat(valorSemDesconto)
    var formatado4 = parseInt(idLead)

    // const wizard = localStorage.getItem('wizard')

    // if (wizard !== "true") {
    //   window.location.href = 'https://solarbr.matogrossoenergia.com.br/'
    // }

    // const wizard = localStorage.getItem('wizard')
    // const objWizard = JSON.parse(wizard)
    // // wizard !== "true"
    // if (objWizard != null) {
    //   if (objWizard.ver !== true || objWizard.pagina !== 3) {
    //     window.location.href = 'https://solarbr.matogrossoenergia.com.br/'
    //   }
    // } else {
    //   window.location.href = 'https://solarbr.matogrossoenergia.com.br/'
    // }

    setValor1Mes(formatado1)
    setValor12Mes(formatado2)
    setValorSemDesconto(formatado3)
    setIdLead(formatado4)
  }, [])

  return (
    <>
      <Formik
        initialValues={{
          termoAdesao: false,
        }}
        validationSchema={AdesaoFormTermoSchema}
        onSubmit={(values) => {
          if (values.termoAdesao == true) {
            var valor = (values.termoAdesao = 'V')
            termoAdesaoSimulacaoLead(idLeadLocal, valor).then(() => {
              localStorage.removeItem('wizard')
              localStorage.setItem(
                'wizard',
                JSON.stringify({ ver: true, pagina: 4 })
              )
              navigate('/adesao/obrigado')
            })
          }
        }}>
        {({ handleSubmit, errors, setFieldValue, values, touched }) => (
          <>
            <AdesaoHeader progress={'60%'} />
            <main className="bg-[#f0efea] px-5 md:px-14 py-10 md:py-16 pt-28 md:mt-0">
              <section className="cadastro">
                <div className="flex justify-center items-center">
                  <div className="bg-white drop-shadow-xl max-w-4xl p-8 w-full rounded-xl border border-gray-300">
                    <h3 className="font-bold text-2xl md:text-3xl text-zinc-800 mb-10">
                      Termos de uso
                    </h3>
                    <div className="max-h-[500px] overflow-y-auto">
                      <p className="paragraph_termo">
                        <strong>1. Termos</strong> <br />
                        <br /> Ao acessar concorda em cumprir estes termos de
                        serviço, todas as leis e regulamentos aplicáveis ​​e
                        concorda que é responsável pelo cumprimento de todas as
                        leis locais aplicáveis. Se você não concordar com algum
                        desses termos, está proibido de usar ou acessar este
                        site. Os materiais contidos neste site são protegidos
                        pelas leis de direitos autorais e marcas comerciais
                        aplicáveis.
                        <br />
                        <br />
                        <strong>2. Uso de Licença</strong> <br />
                        <br /> É concedida permissão para utilizar
                        temporariamente os materiais e análises (informações ou
                        software) nesta plataforma, apenas para visualização
                        transitória pessoal e não comercial. Esta é a concessão
                        de uma licença, não uma transferência de título e, sob
                        esta licença, você não pode:
                        <p />
                        <br />
                        <ul style={{ marginLeft: 24 }}>
                          <li style={{ listStyleType: 'circle' }}>
                            Modificar ou copiar os materiais;
                          </li>
                          <br />
                          <li style={{ listStyleType: 'circle' }}>
                            Usar os materiais para qualquer finalidade comercial
                            ou para exibição pública (comercial ou não
                            comercial);
                          </li>
                          <br />
                          <li style={{ listStyleType: 'circle' }}>
                            Tentar “descompilar” ou fazer engenharia reversa de
                            qualquer software contido nesta plataforma;
                          </li>
                          <br />
                          <li style={{ listStyleType: 'circle' }}>
                            Remover quaisquer direitos autorais ou outras
                            notações de propriedade dos materiais; ou
                          </li>
                          <br />
                          <li style={{ listStyleType: 'circle' }}>
                            Transferir os materiais para outra pessoa ou
                            'espelhe' os materiais em qualquer outro servidor.
                          </li>
                        </ul>
                        <br />
                        <p>
                          Esta licença será automaticamente rescindida se você
                          violar alguma dessas restrições e poderá ser
                          rescindida pela Club Energy - RZK a qualquer momento. Ao
                          encerrar a visualização desses materiais ou após o
                          término desta licença, você deve apagar todos os
                          materiais em sua posse, seja em formato eletrônico ou
                          impresso.
                        </p>
                        <br />
                        <br />
                        <strong>3. Isenção de responsabilidade</strong>
                        <br />
                        <br />
                        Os materiais nesta plataforma são fornecidos &#39;como
                        estão&#39;. A Club Energy - RZK não oferece garantias,
                        expressas ou implícitas, e, por este meio, isenta e nega
                        todas as outras garantias, incluindo, sem limitação,
                        garantias implícitas ou condições de comercialização,
                        adequação a um fim específico ou não violação de
                        propriedade intelectual ou outra violação de direitos.
                        <br />
                        <br />
                        Além disso, a Club Energy - RZK não garante ou faz qualquer
                        representação relativa à precisão, aos resultados
                        prováveis ​​ou à confiabilidade do uso dos materiais em
                        seu site ou de outra forma relacionado a esses materiais
                        ou em sites vinculados a este site.
                        <br />
                        <br />
                        <strong>4. Limitações</strong>
                        <br />
                        <br />
                        Em nenhum caso a Club Energy - RZK ou seus fornecedores
                        serão responsáveis ​​por quaisquer danos (incluindo, sem
                        limitação, danos por perda de dados ou lucro ou devido à
                        interrupção dos negócios) decorrentes do uso ou da
                        incapacidade de usar os materiais nesta plataforma,
                        mesmo que a Club Energy - RZK ou um representante
                        autorizado da Club Energy - RZK tenha sido notificado
                        oralmente ou por escrito da possibilidade de tais danos.
                        Como algumas jurisdições não permitem limitações em
                        garantias implícitas, ou limitações de responsabilidade
                        por danos consequentes ou incidentais, essas limitações
                        podem não se aplicar a você.
                        <br />
                        <br />
                        <strong>5. Precisão dos materiais</strong>
                        <br />
                        <br />
                        Os materiais exibidos nesta plataforma podem incluir
                        erros técnicos, tipográficos ou fotográficos. A TIM
                        ENERGIA não garante que qualquer material em seu site
                        seja preciso, completo ou atual. A Club Energy - RZK pode
                        fazer alterações nos materiais contidos em seu site a
                        qualquer momento, sem aviso prévio. No entanto, a TIM
                        ENERGIA não se compromete a atualizar os materiais.
                        <br />
                        <br />
                        <strong>6. Links</strong>
                        <br />
                        <br />
                        A Club Energy - RZK não analisou todos os sites vinculados
                        ao seu site e não é responsável pelo conteúdo de nenhum
                        site vinculado. A inclusão de qualquer link não implica
                        endosso pela Club Energy - RZK do site. O uso de qualquer
                        site vinculado é por conta e risco do usuário.
                        <br />
                        <br />
                        <strong>7. Modificações</strong>
                        <br />
                        <br />
                        A Club Energy - RZK pode revisar estes termos de serviço do
                        site a qualquer momento, sem aviso prévio. Ao usar este
                        site, você concorda em ficar vinculado à versão atual
                        desses termos de serviço.
                        <br />
                        <br />
                        <strong>8. Lei aplicável</strong>
                        <br />
                        <br />
                        Estes termos e condições são regidos e interpretados de
                        acordo com as leis da Club Energy - RZK e você se submete
                        irrevogavelmente à jurisdição Brasileira, Lei Geral de
                        Proteção de Dados – LGPD, e todos os regulamentos
                        cabíveis sobre o tema nos tribunais do Estado de São
                        Paulo.
                        <br />
                        <br />
                        <strong>9. Direitos</strong>
                        <br />
                        <br />
                        Todos os direitos são reservados à Club Energy - RZK.
                      </p>
                    </div>
                    <Form>
                      <div className="mt-5 flex items-center justify-between">
                        <div className="flex items-center gap-x-2">
                          <Field type="checkbox" name="termoAdesao" Required />
                          <label className="text-zinc-800">
                            &nbsp;Aceito os termos de uso e politicas de
                            privacidade
                          </label>

                          {/* <input type="checkbox" id="termo" name="termo" /> */}

                          {/* <button onClick={() => setChecked((c) => !c)}>
                              &nbsp; Aceite o termo de adesão
                            </button> */}
                        </div>

                        <a
                          // type="button"
                          // href="/adesao/obrigado"
                          className="px-10 py-3 bg-primary text-white font-bold w-fit mt-3 rounded-full hover:opacity-90 cursor-pointer"
                          onClick={() => handleSubmit()}>
                          Continuar
                        </a>
                      </div>
                    </Form>
                  </div>
                  {/* <div className="card cadastro_economia col-xl-4 col-11 col-sm-12">
                    <div className="card-body">
                      <h3 className="cadastro_titulo">Economia Atual</h3>
                      <p className="mb-1">
                        Contrato de <strong className="text-bold">12 meses</strong> economizar
                        projetada de
                      </p>
                      <h3 className="paragraph-valores nowrap">
                        {'R$ ' + numeral(valor12Mes).format('0,0.00')}
                      </h3>
                      <p className="titulo-valores mt-4 mb-1">
                        Benefício
                      </p>
                      <h3 className="paragraph-valores nowrap">15%</h3>
                      <p className="titulo-valores mt-4 mb-1">
                      Valor atual da energia
                      </p>
                      <h3 className="paragraph-valores text-decoration-line-through text-gray-darker nowrap">
                      {'R$ '+numeral(valorSemDesconto).format('0,0.00')}
                      </h3>
                      <p className="titulo-valores mt-4 mb-1">Valor do plano</p>
                      <h3 className="paragraph-valores nowrap mb-2">{'R$ '+numeral(valor1Mes).format('0,0.00')}</h3>
                    </div>
                  </div> */}
                  {/* <EconomiaTotal/> */}
                </div>
              </section>
            </main>
            <FooterAdesao />
          </>
        )}
      </Formik>
    </>
  )
}
