import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { URLRoutes } from './routes'
import './styles.css'
import 'aos/dist/aos.css'
import AOS from 'aos'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import BackdropProvider from './context/Backdrop'

export default function App({ store, basename }) {
  useEffect(() => {
    AOS.init()
  }, [AOS])

  return (
    <Provider store={store}>
      <BackdropProvider>
        <React.Suspense>
          <BrowserRouter basename={basename}>
            <URLRoutes />
            <ToastContainer />
          </BrowserRouter>
        </React.Suspense>
      </BackdropProvider>
    </Provider>
  )
}
