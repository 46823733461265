import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
import { consorcioCocaColaSlice } from "../store";
import { cocaColaSlice } from "../pages/_redux/consorcioCocaCola/CocaColaSlice";



export const rootReducer = combineReducers({
  consorciosCocaCola: consorcioCocaColaSlice.reducer,
  cocacola: cocaColaSlice.reducer,
});

export function* rootSaga() {
  yield all([]);
}
