import axios from "axios";
import { config } from "../../../Config";
export const baseURL = config.url.apiEndPoint;

const api = axios.create();


export async function validarToken(documento) {
  console.log(documento)
  var obj = {
    Email: documento.Email,
    Codigo: documento.Codigo,
  }
  const responses = await api.post(`${baseURL}/obter/informacoes/validar`, {
    Email: documento.documento,
    Codigo: documento.codigo,
  });

  return responses.data.entities;
};

export async function obterDocumento(documento) {
  const response = await api.post(`${baseURL}/obter/informacoes/tim`, {
    Email: documento,
  });

  return response.data.entities;
};