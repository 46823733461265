import React from 'react'
import { useField, useFormikContext } from 'formik'
import { FieldFeedbackLabel } from './FieldFeedbackLabel'
import NumberFormat from 'react-number-format'

const getFieldCSSClasses = (touched, errors) => {
  const classes = ['form-control']
  if (touched && errors) {
    classes.push('is-invalid')
  }

  if (touched && !errors) {
    classes.push('is-valid')
  }

  return classes.join(' ')
}

export function CNPJ({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = 'text',
  ...props
}) {
  return (
    <>
      {label && <label>{label}</label>}
      <NumberFormat
        format="##.###.###/####-##"
        className={getFieldCSSClasses(
          form.touched[field.name],
          form.errors[field.name]
        )}
        {...field}
        {...props}
      />
      {withFeedbackLabel && form.errors[field.name] && (
        <FieldFeedbackLabel
          error={form.errors[field.name]}
          touched={form.touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  )
}
