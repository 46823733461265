// import api from './api';
import axios from 'axios'
import { config } from '../../Config'
export const baseURL = config.url.apiEndPoint

const api = axios.create()

export function incluirSimulacaoLead(obj) {
  return new Promise(async (resolve, reject) => {
    const response = await api.post(
      `${baseURL}/simulacao/cocacola/lead/incluir`,
      {
        Nome: obj.nome,
        Cnpj: obj.cnpj,
        Cpf: obj.cpf,
        Email: obj.email,
        Telefone: obj.telefone,
        StrParametro7: obj.strParametro7,
        IntParametro1: obj.intParametro1,
        DecParametro3: obj.decParametro3,
        DecParametro4: obj.decParametro3,
        DecParametro5: obj.decParametro5,
        TipoParceiro: obj.tipoParceiro,
      }
    )
    resolve(response.data.entities)
  })
}

export function alterarSimulacaoLeadv1(
  idLead,
  nome,
  email,
  telefone,
  strParametro7,
  intParametro1,
  decParametro3,
  decParametro4
) {
  return new Promise(async (resolve, reject) => {
    const response = await api.post(
      `${baseURL}/simulacao/cocacola/lead/alterar_v1`,
      {
        idLead,
        nome,
        email,
        telefone,
        strParametro7,
        intParametro1,
        decParametro3,
        decParametro4,
      }
    )
    resolve(response.data.entities)
  })
}

export function alterarSimulacaoLead(
  idLead,
  strParametro5,
  strParametro6,
  faturaURL,
  faturaPath,
  nomeArquivo
) {
  return new Promise(async (resolve, reject) => {
    const response = await api.post(
      `${baseURL}/simulacao/cocacola/lead/alterar`,
      {
        idLead,
        strParametro5,
        strParametro6,
        faturaURL,
        faturaPath,
        nomeArquivo,
      }
    )
    resolve(response.data.entities)
  })
}

export function alterarSimulacaoLeadUnificadoHash(
  hash,
  faturaURL,
  faturaPath,
  nomeArquivo,
  situacao
) {
  return new Promise(async (resolve, reject) => {
    const response = await api.post(
      `${baseURL}/simulacao/cocacola/lead/alterarUnificadoHash`,
      {
        hash,
        faturaURL,
        faturaPath,
        nomeArquivo,
        situacao,
      }
    )
    resolve(response.data.entities)
  })
}

export function alterarSimulacaoLeadUnificado(
  idLead,
  strParametro5,
  strParametro6,
  faturaURL,
  faturaPath,
  nomeArquivo,
  situacao
) {
  return new Promise(async (resolve, reject) => {
    const response = await api.post(
      `${baseURL}/simulacao/cocacola/lead/alterarUnificado`,
      {
        idLead,
        strParametro5,
        strParametro6,
        faturaURL,
        faturaPath,
        nomeArquivo,
        situacao,
      }
    )
    resolve(response.data.entities)
  })
}

export function termoAdesaoSimulacaoLead(ids, situacao) {
  return new Promise(async (resolve, reject) => {
    const response = await api.post(
      `${baseURL}/simulacao/cocacola/lead/termoAdesao`,
      {
        ids,
        situacao,
      }
    )
    resolve(response.data.entities)
  })
}

export function incluirLeadGeneric(obj) {
  return new Promise(async (resolve, reject) => {
    const response = await api.post(`${baseURL}/lead/incluir/generic_v2`, obj)
    resolve(response.data)
  })
}
