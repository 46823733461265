import React from 'react'
import EtapasCard from './EtapasCard'

const cardInfos = [
  {
    titulo: 'Etapa 1',
    subtitulo: 'Processamento de faturas',
    texto:
      'Tenha todos os processos automatizados a partir da captura dos dados da fatura e preparação para pagamento diretamente no seu sistema ERP.',
    descricao1:
      'Garantia de que a distribuidora de energia disponibiliza as faturas no prazo correto.',
    descricao2: 'Melhoria do controle financeiro e gestão do fluxo de caixa.',
    descricao3:
      'Eliminação de multas, juros e cortes de energia por atrasos no pagamento.',
  },
  {
    titulo: 'Etapa 2',
    subtitulo: 'Auditoria e ressarcimento',
    texto:
      'Temos uma base de mais de 350 erros de faturamento comuns às distribuidoras de energia – verificamos a menor inconsistência em cada uma de suas contas.',
    descricao1:
      'Garantia de que sua empresa paga apenas o que de fato consome.',
    descricao2:
      'Garantia de ressarcimento e indenização em casos de cobranças indevidas.',
    descricao3: 'Identificação imediata de novos erros nas cobranças.',
  },
  {
    titulo: 'Etapa 3',
    subtitulo: 'Gestão de energia e crédito fiscal',
    texto:
      'Todos os meses, analisamos sua evolução energética para garantir que você pague sempre o mínimo possível pela energia, sem perder em produtividade.',
    descricao1:
      'Otimize sua operação para garantir um consumo eficiente e barato de energia.',
    descricao2:
      'Tenha acesso a diversos mercados de energia e encontre o modelo mais vantajoso para o seu negócio.',
    descricao3:
      'Saiba se sua compensação tributária está correta. Em caso de erros, receba crédito fiscal dos órgãos governamentais.',
  },
]

export default function Etapas() {
  return (
    <div
      id="servicos"
      className="w-full pb-8 relative max-w-7xl mx-auto flex flex-col px-4 lg:px-0 items-center justify-center gap-[61px] xl:px-0"
      style={{
        backgroundImage: `url('/assets/img/bg-blue.svg')`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'unset',
      }}>
      <div className="grid lg:grid-cols-3 grid-cols-1 grid-rows-1 gap-3 w-full lg:px-4">
        {cardInfos.map((item, index) => (
          <EtapasCard
            key={index}
            titulo={item.titulo}
            subtitulo={item.subtitulo}
            texto={item.texto}
            desc1={item.descricao1}
            desc2={item.descricao2}
            desc3={item.descricao3}
          />
        ))}
      </div>
      <div className="w-full flex flex-col gap-5 justify-start items-center pb-16 ">
        <h2 className="text-center text-slate-800 lg:text-5xl text-3xl font-medium lg:leading-[64px] leading-[48px] w-full">
          Tenha um plano de ação inteligente
        </h2>
        <p className="text-center text-slate-800 lg:text-2xl text-xl font-normal lg:leading-[33px] leading-[30px]">
          Vamos te ajudar a reduzir custos de energia, otimizar seu
          <br />
          consumo e obter a melhor performance contratual nos
          <br />
          diversos mercados de energia.
        </p>
        <a
          target="_blank"
          href="https://wa.me/5515998238806?text=Ol%C3%A1!%20Sou%20cliente%20Vivo%20Valoriza%20Empresas%20e%20quero%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20servi%C3%A7o%20de%20Gest%C3%A3o%20de%20Faturas"
          className="w-fit h-11 mt-11 lg:px-16 px-4 py-3 bg-white hover:opacity-80 transition ease-in-out duration-200 rounded-full border border-cyan-400 justify-center items-start flex">
          <div className="text-center text-slate-800 text-sm font-medium leading-tight">
            Quero falar com um especialista
          </div>
        </a>
      </div>
    </div>
  )
}
