import React from 'react'
import { useTheme, useMediaQuery } from '@mui/material'

export default function Auditoria() {
  const theme = useTheme()
  const is1024Down = useMediaQuery(theme.breakpoints.down(1024))
  return (
    <div
      style={{
        backgroundImage: `url('/assets/img/bg.svg')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}>
      <div className="w-full max-w-7xl flex flex-col items-center justify-center gap-8 px-4 xl:px-0 py-20 mx-auto">
        <div className="w-full lg:space-y-5 space-y-12">
          <div className="w-full space-y-8">
            <h4 className="w-full max-w-[566px] text-[#142a42] lg:text-5xl text-4xl font-medium font-['Plus Jakarta Sans'] lg:leading-[64.80px]">
              Por que a auditoria de faturas é essencial?
            </h4>
            <p className="w-full max-w-[615px] text-[#142a42] lg:text-2xl text-xl font-normal font-['Plus Jakarta Sans'] lg:leading-[33.60px]">
              É estimado que as distribuidoras de energia tenham faturado mais
              de R$300 bilhões só em 2023.
            </p>
          </div>
          <div className="w-full flex flex-col items-center space-y-8">
            <div className="w-full max-w-[633px] lg:mt-16 mt-1">
              <span className="text-[#142a42] lg:text-2xl text-xl font-normal font-['Plus Jakarta Sans'] leading-[33.60px]">
                O que você talvez não saiba é que os erros de faturamento
                representam aproximadamente{' '}
              </span>
              <span className="text-[#142a42] lg:text-2xl text-xl font-bold font-['Plus Jakarta Sans'] leading-[33.60px]">
                3% no valor das contas de energia
              </span>
              <span className="text-[#142a42] lg:text-2xl text-xl font-normal font-['Plus Jakarta Sans'] leading-[33.60px]">
                , ou seja, isso significa um valor aproximado de
              </span>
              <span className="text-[#142a42] lg:text-2xl text-xl font-bold font-['Plus Jakarta Sans'] leading-[33.60px]">
                {' '}
                <br />
                R$9 bilhões em cobranças indevidas por ano.
              </span>
            </div>
            <div className="w-full max-w-[633px] text-[#142a42] text-2xl font-normal font-['Plus Jakarta Sans'] leading-[33.60px]">
              As principais reclamações de erros em faturas de energia estão
              relacionadas a mercado livre de energia, geração distribuída e
              qualidade de rede.{' '}
            </div>
          </div>
        </div>
        <div className="w-full max-w-[910px] text-center lg:mt-20 mt-5">
          <span className="text-[#142a42] lg:text-[34px] text-[24px] font-normal font-['Plus Jakarta Sans'] lg:leading-[45.90px]">
            A Amee examina e cuida de todos esses pontos nas auditorias de
            faturas para garantir que sua empresa{' '}
          </span>
          <span className="text-[#142a42] lg:text-[34px] text-[24px] font-bold font-['Plus Jakarta Sans'] lg:leading-[45.90px]">
            não pague nem um centavo em cobranças indevidas
          </span>
          <span className="text-[#142a42] lg:text-[34px] text-[24px] font-normal font-['Plus Jakarta Sans'] lg:leading-[45.90px]">
            .
          </span>
        </div>
      </div>
    </div>
  )
}
