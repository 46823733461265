import numeral from "numeral";
// Numeral.locale('pt');

numeral.register('locale', 'brasil', { 
  delimiters: { 
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'mil',
    million: 'milhões',
    billion: 'b',
    trillion: 't',
  },
  ordinal: () => '',
  currency: {
    symbol: 'R$',
  }
});

numeral.locale('brasil');

export default numeral;