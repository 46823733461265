import React from 'react'

import { useMediaQuery, useTheme } from '@mui/material'
import ReactPlayer from 'react-player'

export default function HeroWhite() {
  const theme = useTheme()
  const is960Up = useMediaQuery(theme.breakpoints.up(960))

  return (
    <>
      <div
        id="hero"
        className="md:px-0 lg:h-[60rem] h-[58rem] overflow-hidden px-2 lg:pb-24 pb-12 pt-[10rem] lg:pt-[13rem] relative w-full flex justify-center items-center bg-transparent">
        <img
          src="/assets/img/blur_blue2.svg"
          alt="Fundo ilustração"
          className="absolute inset-0 -top-80 -right-20 w-full z-0"
        />
      </div>
      <div
        id="hero"
        className="-mt-[60rem] md:px-0 lg:h-[60rem] h-[68rem] overflow-hidden px-2 lg:pb-24 pb-12 pt-[1rem] lg:pt-[13rem] relative w-full flex justify-center items-center bg-transparent">
        <div className="z-10 absolute inset-0">
          <img
            src="/assets/img/fundo_hero.svg"
            alt="Fundo ilustração"
            className="transform scale-[1.1] w-full h-full z-30"
          />
        </div>
        <div className="z-20 w-full max-w-7xl flex flex-col items-center justify-center gap-8 px-4 xl:px-0 relative">
          <div className="space-y-3 z-10">
            <h1 className="text-[2rem] text-center font-medium text-[#142A42]">
              Não pague a mais pela sua energia
            </h1>
            <p className="text-center text-base text-[#142A42]">
              Deixe os especialistas da Amee gerenciarem suas contas de energia.
              <br />
              <strong>Clientes Vivo Valoriza têm 15% de desconto.</strong>{' '}
              Aproveite!
              <br />
              <br />
              Veja o vídeo e entenda como nosso diagnóstico te ajuda a
              economizar:
            </p>
          </div>
          <ReactPlayer
            url="https://www.youtube.com/watch?v=xGEbfEuP9JQ"
            width={is960Up ? 845 : '98%'}
            height={476}
            className="shadow-2xl rounded"
            config={{
              youtube: {
                playerVars: { showinfo: 1 },
              },
            }}
          />
          <a
            href="#form-white-contato"
            className="mt-10 h-11 px-8 py-3 bg-cyan-400 hover:bg-cyan-200 transition ease-in-out duration-200 rounded-full border border-cyan-400 justify-center items-start flex">
            <div className="text-center text-slate-800 text-sm font-medium leading-tight">
              Quero fazer um diagnóstico
            </div>
          </a>
        </div>
      </div>
    </>
  )
}
