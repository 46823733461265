import React, { useState } from 'react'
import {
  HiDotsHorizontal,
  HiMenu,
  HiOutlineQuestionMarkCircle,
  HiOutlineUserCircle,
  HiOutlineXCircle,
} from 'react-icons/hi'

export default function AdesaoHeader({ progress }) {
  const [mobileMenu, setMobileMenu] = useState(false)
  return (
    <>
      <header className="px-5 md:px-14 py-3 bg-white flex items-center gap-8 w-full">
        <a href="/">
          <img
            src="/logo/logo-colered.svg"
            alt="Logo Grupo Mendes"
            className="w-28 h-28 -my-6"
          />
        </a>
        <div class="w-full bg-gray-200 h-2.5 ">
          <div class={`bg-primary h-2.5 w-[${progress}]`}></div>
        </div>
      </header>
    </>
  )
}
