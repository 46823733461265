import React, { useState } from 'react'

import { useMediaQuery, useTheme } from '@mui/material'
import ReactPlayer from 'react-player'

export default function ProstaNaoEnviadaHero() {
  const theme = useTheme()
  const is960Up = useMediaQuery(theme.breakpoints.up(960))

  const [nome] = useState(localStorage.getItem('proposta_nome') || 'Senhor(a)')
  return (
    <>
      <div className="z-20 w-full max-w-7xl flex flex-col items-center justify-center gap-8 px-4 mx-auto xl:px-0 relative lg:pt-12 pt-5 lg:pb-24 pb-10">
        <div className="flex flex-col justify-center items-start w-full pt-14">
          <h3 className="w-fit text-slate-800 text-[28px] font-medium">
            {`Obrigado, ${nome}`}
          </h3>
          <p className="w-full max-w-[670px] text-slate-800 mt-6 text-[28px] font-medium">
            Por favor, envie sua conta de energia para que possamos fazer um
            diagnóstico completo.
          </p>
          <div className="w-full max-w-[786px] flex justify-start items-center mt-4">
            <a
              href="/#form-white-contato"
              className="w-full max-w-[786px] mt-6 h-14 px-8 lg:py-3 py-1 bg-cyan-400 hover:bg-cyan-200 transition ease-in-out duration-200 rounded-full border border-cyan-400 justify-center items-center flex disabled:opacity-50 disabled:cursor-not-allowed">
              <div className="text-center text-slate-800 lg:text-sm text-xs font-medium leading-tight">
                Clique aqui para enviar sua conta e começar a economizar hoje
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="z-0 md:px-0 -mt-60 h-[60rem] overflow-hidden px-2 lg:pb-24 pb-12 pt-[10rem] lg:pt-[13rem] relative w-full flex justify-center items-center bg-transparent">
        <img
          src="/assets/img/blur_blue2.svg"
          alt="Fundo ilustração"
          className="fixed inset-0 -right-12 w-full z-0 opacity-0 hidden lg:block"
        />
      </div>
      <div
        id="hero"
        className="-mt-[60rem] md:px-0 lg:h-[60rem] h-[68rem] overflow-hidden px-2 lg:pb-24 h pb-12 pt-[10rem] lg:pt-[13rem] relative w-full flex justify-center items-center bg-transparent">
        <div className="z-10 absolute inset-0">
          <img
            src="/assets/img/fundo_hero.svg"
            alt="Fundo ilustração"
            className="transform scale-[1.1] w-full h-full z-30"
          />
        </div>
        <div className="z-20 w-full max-w-7xl flex flex-col items-center justify-center gap-8 px-4 xl:px-0 relative">
          <div className="space-y-3 z-10">
            <h1 className="lg:text-[2rem] text-[1.5rem] text-center font-medium text-[#142A42]">
              Como funciona o diagnóstico da Amee
            </h1>
            <p className="text-center text-base text-[#142A42]">
              Ainda na dúvida? Entenda como funciona o diagnóstico da Amee: 
            </p>
          </div>
          <ReactPlayer
            url="https://www.youtube.com/watch?v=nlbNjxPdJEc"
            width={is960Up ? 845 : '98%'}
            height={476}
            className="shadow-2xl rounded"
            config={{
              youtube: {
                playerVars: { showinfo: 1 },
              },
            }}
          />
        </div>
      </div>
    </>
  )
}
