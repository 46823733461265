import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import * as actions from '../../pages/_redux/consorcioCocaCola/CocaColaActions'
import { Formik, Form, Field } from 'formik'
import { obterDocumento } from '../../pages/_redux/consorcioCocaCola/CocaColaCRUD'

import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'

const ModalTermos = ({ onClose = () => { }, obj, page }) => {
  const [open, setOpen] = useState(obj.obj)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const [documentoValidado, setDocumentoValidado] = useState('abrirModal')
  const [documento, setDocumento] = useState()

  const schema = Yup.object().shape({
    documento: Yup.string().required('Preencha o documento'),
  })
  const dispatch = useDispatch();
  const [tabModal, setTabModal] = useState(null);
  useEffect(() => {
    handleOpen()
  }, [obj])

  useEffect(() => {
    setTabModal(page)
  }, [page, dispatch]);
  return (
    <>

      <Modal
        open={open}
        close={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Formik
        >
          {({ errors, handleSubmit }) => (
            <Box className="custom-modal">
              <Form>
                <div className="md:px-14 px-10 py-16 flex justify-center items-center">
                  <div className="bg-white drop-shadow-xl max-w-4xl p-8 w-full rounded-xl border border-gray-300">
                    {tabModal === 'termos' &&
                      <>
                        <h3 className="font-bold text-2xl md:text-3xl text-zinc-800 mb-10">
                          Termos de uso
                        </h3>
                        <div className="max-h-[500px] overflow-y-auto">
                          <p className="paragraph_termo">
                            <strong>1. Termos</strong> <br />
                            <br /> Ao acessar concorda em cumprir estes termos de
                            serviço, todas as leis e regulamentos aplicáveis ​​e
                            concorda que é responsável pelo cumprimento de todas as
                            leis locais aplicáveis. Se você não concordar com algum
                            desses termos, está proibido de usar ou acessar este
                            site. Os materiais contidos neste site são protegidos
                            pelas leis de direitos autorais e marcas comerciais
                            aplicáveis.
                            <br />
                            <br />
                            <strong>2. Uso de Licença</strong> <br />
                            <br /> É concedida permissão para utilizar
                            temporariamente os materiais e análises (informações ou
                            software) nesta plataforma, apenas para visualização
                            transitória pessoal e não comercial. Esta é a concessão
                            de uma licença, não uma transferência de título e, sob
                            esta licença, você não pode:
                            <p />
                            <br />
                            <ul style={{ marginLeft: 24 }}>
                              <li style={{ listStyleType: 'circle' }}>
                                Modificar ou copiar os materiais;
                              </li>
                              <br />
                              <li style={{ listStyleType: 'circle' }}>
                                Usar os materiais para qualquer finalidade comercial
                                ou para exibição pública (comercial ou não
                                comercial);
                              </li>
                              <br />
                              <li style={{ listStyleType: 'circle' }}>
                                Tentar “descompilar” ou fazer engenharia reversa de
                                qualquer software contido nesta plataforma;
                              </li>
                              <br />
                              <li style={{ listStyleType: 'circle' }}>
                                Remover quaisquer direitos autorais ou outras
                                notações de propriedade dos materiais; ou
                              </li>
                              <br />
                              <li style={{ listStyleType: 'circle' }}>
                                Transferir os materiais para outra pessoa ou
                                'espelhe' os materiais em qualquer outro servidor.
                              </li>
                            </ul>
                            <br />
                            <p>
                              Esta licença será automaticamente rescindida se você
                              violar alguma dessas restrições e poderá ser
                              rescindida pela Club Energy - RZK a qualquer momento. Ao
                              encerrar a visualização desses materiais ou após o
                              término desta licença, você deve apagar todos os
                              materiais em sua posse, seja em formato eletrônico ou
                              impresso.
                            </p>
                            <br />
                            <br />
                            <strong>3. Isenção de responsabilidade</strong>
                            <br />
                            <br />
                            Os materiais nesta plataforma são fornecidos &#39;como
                            estão&#39;. A Club Energy - RZK não oferece garantias,
                            expressas ou implícitas, e, por este meio, isenta e nega
                            todas as outras garantias, incluindo, sem limitação,
                            garantias implícitas ou condições de comercialização,
                            adequação a um fim específico ou não violação de
                            propriedade intelectual ou outra violação de direitos.
                            <br />
                            <br />
                            Além disso, a Club Energy - RZK não garante ou faz qualquer
                            representação relativa à precisão, aos resultados
                            prováveis ​​ou à confiabilidade do uso dos materiais em
                            seu site ou de outra forma relacionado a esses materiais
                            ou em sites vinculados a este site.
                            <br />
                            <br />
                            <strong>4. Limitações</strong>
                            <br />
                            <br />
                            Em nenhum caso a Club Energy - RZK ou seus fornecedores
                            serão responsáveis ​​por quaisquer danos (incluindo, sem
                            limitação, danos por perda de dados ou lucro ou devido à
                            interrupção dos negócios) decorrentes do uso ou da
                            incapacidade de usar os materiais nesta plataforma,
                            mesmo que a Club Energy - RZK ou um representante
                            autorizado da Club Energy - RZK tenha sido notificado
                            oralmente ou por escrito da possibilidade de tais danos.
                            Como algumas jurisdições não permitem limitações em
                            garantias implícitas, ou limitações de responsabilidade
                            por danos consequentes ou incidentais, essas limitações
                            podem não se aplicar a você.
                            <br />
                            <br />
                            <strong>5. Precisão dos materiais</strong>
                            <br />
                            <br />
                            Os materiais exibidos nesta plataforma podem incluir
                            erros técnicos, tipográficos ou fotográficos. A TIM
                            ENERGIA não garante que qualquer material em seu site
                            seja preciso, completo ou atual. A Club Energy - RZK pode
                            fazer alterações nos materiais contidos em seu site a
                            qualquer momento, sem aviso prévio. No entanto, a TIM
                            ENERGIA não se compromete a atualizar os materiais.
                            <br />
                            <br />
                            <strong>6. Links</strong>
                            <br />
                            <br />
                            A Club Energy - RZK não analisou todos os sites vinculados
                            ao seu site e não é responsável pelo conteúdo de nenhum
                            site vinculado. A inclusão de qualquer link não implica
                            endosso pela Club Energy - RZK do site. O uso de qualquer
                            site vinculado é por conta e risco do usuário.
                            <br />
                            <br />
                            <strong>7. Modificações</strong>
                            <br />
                            <br />
                            A Club Energy - RZK pode revisar estes termos de serviço do
                            site a qualquer momento, sem aviso prévio. Ao usar este
                            site, você concorda em ficar vinculado à versão atual
                            desses termos de serviço.
                            <br />
                            <br />
                            <strong>8. Lei aplicável</strong>
                            <br />
                            <br />
                            Estes termos e condições são regidos e interpretados de
                            acordo com as leis da Club Energy - RZK e você se submete
                            irrevogavelmente à jurisdição Brasileira, Lei Geral de
                            Proteção de Dados – LGPD, e todos os regulamentos
                            cabíveis sobre o tema nos tribunais do Estado de São
                            Paulo.
                            <br />
                            <br />
                            <strong>9. Direitos</strong>
                            <br />
                            <br />
                            Todos os direitos são reservados à Club Energy - RZK.
                          </p>
                        </div>
                      </>
                    }
                    {tabModal === 'politica' &&
                      <>
                        <h3 className="font-bold text-2xl md:text-3xl text-zinc-800 mb-10">
                          Politicas de privacidade
                        </h3>
                        <div className="max-h-[500px] overflow-y-auto">

                        </div>
                      </>
                    }
                    <div className="mt-5 flex items-center justify-between">
                      <div className="flex items-center gap-x-2"></div>
                      <a
                        className="px-10 py-3 bg-secondary text-white font-bold w-fit mt-3 rounded-full hover:bg-[#4e7c14] cursor-pointer"
                        // onClick={() => handleClose()}
                        onClick={onClose}
                      >
                        fechar
                      </a>
                    </div>
                  </div>
                </div>


              </Form>
            </Box>
          )}
        </Formik>
      </Modal>
    </>
  )
}

export default ModalTermos;