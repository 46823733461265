import React from 'react'
import { useTheme, useMediaQuery } from '@mui/material'
import { CheckIcon } from '@heroicons/react/outline'

export default function Vantagens() {
  const theme = useTheme()
  const is1200Down = useMediaQuery(theme.breakpoints.down(1200))

  return (
    <div className="w-full min-h-[28rem] bg-slate-800">
      <div className="w-full max-w-7xl flex lg:flex-row flex-col items-center justify-around gap-8 px-4 xl:px-0 mx-auto">
        <div className="min-h-full -mt-11 max-lg:hidden">
          <img
            src="/assets/img/vantagens-people.svg"
            alt="Vantagens"
            className="object-contain h-[48rem] -mt-14"
          />
        </div>
        <div className="lg:py-24 pt-24 pb-2">
          <h4 className="w-full max-w-[489px] text-white lg:text-5xl text-4xl font-medium lg:leading-[64.80px]">
            Vantagens de ter um Gestor de Energia
          </h4>
          <div className="mt-10 space-y-4">
            <div className="flex justify-start items-center gap-4">
              <CheckIcon className="h-6 w-6 text-green-300" />
              <p className="w-fit h-6 text-white text-base font-normal leading-snug">
                Redução de custos operacionais
              </p>
            </div>
            <div className="flex justify-start items-center gap-4">
              <CheckIcon className="h-6 w-6 text-green-300" />
              <p className="w-fit h-6 text-white text-base font-normal leading-snug">
                Diminuição de gastos com energia
              </p>
            </div>
            <div className="flex justify-start items-center gap-4">
              <CheckIcon className="h-6 w-6 text-green-300" />
              <p className="w-fit h-6 text-white text-base font-normal leading-snug">
                Melhoria da sustentabilidade ambiental
              </p>
            </div>
            <div className="flex justify-start items-center gap-4">
              <CheckIcon className="h-6 w-6 text-green-300" />
              <p className="w-fit h-6 text-white text-base font-normal leading-snug">
                Redução da pegada de carbono
              </p>
            </div>
            <div className="flex justify-start items-center gap-4">
              <CheckIcon className="h-6 w-6 text-green-300" />
              <p className="w-fit h-6 text-white text-base font-normal leading-snug">
                Conformidade com regulamentações e normas energéticas
              </p>
            </div>
          </div>
          <a
            className="lg:w-fit w-full lg:mt-10 mt-16 h-11 px-8 py-3 bg-cyan-400 hover:bg-cyan-200 transition ease-in-out duration-200 rounded-full border border-cyan-400 justify-center items-start flex disabled:opacity-50 disabled:cursor-not-allowed"
            href="#form-white-contato">
            <div className="text-center text-slate-800 text-sm font-medium leading-tight">
              Quero fazer um diagnóstico
            </div>
          </a>
        </div>
      </div>
      <div className="min-h-full max-md:flex hidden">
        <img
          src="/assets/img/vantagens-people.svg"
          alt="Vantagens"
          className="object-contain"
        />
      </div>
    </div>
  )
}
