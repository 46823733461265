//Importação React
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import FooterAdesao from '../../../../../components/adesao/adesao_footer'
import AdesaoHeader from '../../../../../components/adesao/adesao_header'
import numeral from '../../../../../components/control/numeralBRL'
import '../cadastro.css'
import EconomiaTotal from '../components/EconomiaTotal'

export default function Cadastro4() {
  const fluxoConsorcio = useSelector((state) => state)

  const [valor1Mes, setValor1Mes] = useState(0)
  const [valor12Mes, setValor12Mes] = useState(0)
  const [valorSemDesconto, setValorSemDesconto] = useState(0)

  useEffect(() => {
    // if(fluxoConsorcio.consorciosCocaCola.valor1Mes  !=0 && fluxoConsorcio.consorciosCocaCola.valor12Mes !=0 && fluxoConsorcio.consorciosCocaCola.valorSemDesconto !=0 ){
    //   setValor1Mes(fluxoConsorcio.consorciosCocaCola.valor1Mes);
    //   setValor12Mes(fluxoConsorcio.consorciosCocaCola.valor12Mes);
    //   setValorSemDesconto(fluxoConsorcio.consorciosCocaCola.valorSemDesconto);
    // }

    var valormes1 = localStorage.getItem('valor1Mes')
    var valormes12 = localStorage.getItem('valor12Mes')
    var valorSemDesconto = localStorage.getItem('valorDesconto')
    var formatado1 = parseFloat(valormes1)
    var formatado2 = parseFloat(valormes12)
    var formatado3 = parseFloat(valorSemDesconto)

    // const wizard = localStorage.getItem('wizard')

    // if (wizard !== "true") {
    //   window.location.href = 'http://localhost:3000/'
    // }

    // const wizard = localStorage.getItem('wizard')
    // const objWizard = JSON.parse(wizard)
    // // wizard !== "true"
    // if (objWizard != null) {
    //   if (objWizard.ver !== true || objWizard.pagina !== 4) {
    //     window.location.href = 'http://localhost:3000/'
    //   }
    // } else {
    //   window.location.href = 'http://localhost:3000/'
    // }

    setValor1Mes(formatado1)
    setValor12Mes(formatado2)
    setValorSemDesconto(formatado3)
  }, [])

  return (
    <>
      <AdesaoHeader progress={'100%'} />
      <main className="bg-[#f0efea] px-5 md:px-14 py-10 md:py-16 pt-28 md:mt-0">
        <section>
          <div className=" flex justify-center items-center">
            <div className="bg-white drop-shadow-xl p-8 max-w-xl rounded-xl border border-gray-300">
              <div>
                <h3 className="font-bold text-2xl md:text-4xl text-zinc-800 mb-2 text-center">
                  Obrigado!
                </h3>
                <h3 className="text-xl font-normal text-zinc-700 text-center">
                  Em breve entraremos em contato para finalizar a contratação
                </h3>
                <div className="flex items-center justify-center">
                  <img
                    src="/icons/badge-check.svg"
                    alt="Check"
                    className="py-5 "
                    style={{ width: '30%' }}
                  />
                </div>
                <div className="flex flex-col justify-center items-center">
                  <p className="text-lg text-zinc-700 mb-1 text-center">
                    Em caso de dúvida entre em contato com a gente
                  </p>
                  <a
                    href="#"
                    className="text-xl text-zinc-800 font-semibold text-center w-full">
                    <u>contato@rzkenergia.com.br</u>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <FooterAdesao />
    </>
  )
}
