import React from 'react'
import { useField, useFormikContext } from 'formik'
import { FieldFeedbackLabel } from './FieldFeedbackLabel'
import NumberFormat from 'react-number-format'

const getFieldCSSClasses = (touched, errors) => {
  const classes = ['form-control']
  if (touched && errors) {
    classes.push('border-b border-b-red-500 text-red-500 text-sm')
  }

  if (touched && !errors) {
    classes.push('border-b border-b-green-500 text-green-500 text-sm')
  }

  return classes.join(' ')
}

export function Telefone({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = 'text',
  ...props
}) {
  return (
    <>
      <div className="flex flex-col py-2">
        {label && (
          <label className="text-base font-medium text-zinc-700">{label}</label>
        )}
        <NumberFormat
          format="(##) #####-####"
          type={type}
          className={` appearance-none bg-transparent border-none w-full text-base text-gray-700 py-1 leading-tigh border-transparent focus:border-transparent focus:ring-0 px-0 h-12 ${getFieldCSSClasses(
            form.touched[field.name],
            form.errors[field.name]
          )}`}
          {...field}
          {...props}
        />
      </div>
      {withFeedbackLabel && form.errors[field.name] && (
        <FieldFeedbackLabel
          error={form.errors[field.name]}
          touched={form.touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  )
}
