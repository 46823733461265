import React, { useState } from 'react'

import { useTheme, useMediaQuery } from '@mui/material'
import FormHero from './FormHero'

export default function FormWhite() {
  const [dadosEnviados, setDadosEnviados] = useState(false)
  const [formPasso, setFormPasso] = useState(1)

  const theme = useTheme()
  const is1024Down = useMediaQuery(theme.breakpoints.down(1024))

  return (
    <div
      className="w-full max-w-7xl flex lg:flex-row flex-col items-center justify-center gap-8 px-4 xl:px-0 lg:py-16 mx-auto"
      style={{
        backgroundImage: `url('/assets/img/bg.svg')`,
        backgroundSize: is1024Down ? 'cover' : 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}>
      <div className="lg:space-y-5 space-y-7">
        <h3 className="w-full max-w-[595px]">
          <span className="text-[#142a42] lg:text-5xl text-3xl font-medium font-['Plus Jakarta Sans'] lg:leading-[64.80px]">
            Você pode estar pagando até 50% a mais em energia por não ter um{' '}
          </span>
          <span className="lg:text-5xl text-3xl font-medium font-['Plus Jakarta Sans'] lg:leading-[64.80px] from-[#642ced] to-[#279FF8] bg-gradient-to-r via-[#642ced] inline-block text-transparent bg-clip-text">
            Gestor de Energia
          </span>
        </h3>
        <div className="w-full max-w-[419px] text-slate-800 text-base font-normal leading-normal">
          Contratos onerosos e cobranças indevidas na conta de luz são mais
          comuns do que parecem e são apenas alguns dos vários problemas que
          causam gastos desnecessários com energia para empresas.
          <br />
          <br />A Amee possui especialistas em energia preparados para evitar
          problemas assim e encontrar soluções inteligentes para o uso de
          energia na sua empresa.
          <br />
        </div>
        <div className="max-w-[595px]">
          <span className="text-[#142a42] lg:text-[34px] text-[20px] font-normal font-['Plus Jakarta Sans'] lg:leading-[45.90px]">
            Comece a economizar hoje - <br />
          </span>
          <span className="text-[#142a42] lg:text-[34px] text-[20px] font-bold font-['Plus Jakarta Sans'] lg:leading-[45.90px]">
            Planos a partir de R$6,00{' '}
          </span>
        </div>
      </div>
      <div className="flex-[1] flex justify-center w-full lg:min-h-[612px] my-4 lg:my-0">
        {!dadosEnviados ? (
          <FormHero
            formPasso={formPasso}
            setFormPasso={setFormPasso}
            setDadosEnviados={setDadosEnviados}
          />
        ) : (
          <div className="max-w-[500px] h-full flex-col justify-start items-start gap-8 flex">
            <div className="self-stretch h-7 text-white text-4xl font-semibold leading-snug">
              Obrigado!
            </div>
            <div className="w-full">
              <span className="text-white text-opacity-90 text-2xl font-normal leading-10">
                Seu contato foi salvo com{' '}
              </span>
              <span className="text-green-400 text-2xl font-semibold leading-10">
                sucesso
              </span>
              <span className="text-white text-opacity-90 text-2xl font-normal leading-10">
                ! Iremos processar sua(s) fatura(s) e enviar o relatório
                detalhado em até 7 dias.
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
