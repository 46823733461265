import React from 'react'

export default function EmpresasParceiras() {
  return (
    <>
      <div className="w-full max-w-7xl hidden md:flex lg:flex-row flex-col items-center justify-around gap-8 px-4 xl:px-0 py-20 mx-auto">
        <div>
          <h3 className="w-full text-center text-slate-800 text-[34px] font-normal leading-[45.90px]">
            Empresas que aprovam e confiam na Amee
          </h3>
          <div className="grid grid-cols-3 lg:grid-cols-5 gap-2 justify-center items-center mt-8">
            <img
              src="/assets/img/empresas_parceiras_imagem_2.svg"
              alt="Empresas Parceiras "
              className="w-[11.25rem] h-[5rem]"
            />
            <img
              src="/assets/img/empresas_parceiras_imagem_3.svg"
              alt="Empresas Parceiras "
              className="w-[11.25rem] h-[5rem]"
            />
            <img
              src="/assets/img/empresas_parceiras_imagem_4.svg"
              alt="Empresas Parceiras "
              className="w-[11.25rem] h-[5rem]"
            />
            <img
              src="/assets/img/empresas_parceiras_imagem_5.svg"
              alt="Empresas Parceiras "
              className="w-[11.25rem] h-[5rem]"
            />
            <img
              src="/assets/img/empresas_parceiras_imagem_6.svg"
              alt="Empresas Parceiras "
              className="w-[11.25rem] h-[5rem]"
            />
          </div>
          <div className="grid lg:grid-cols-5 grid-cols-3 gap-4 items-center mt-8">
            <img
              src="/assets/img/empresas_parceiras_imagem_8.svg"
              alt="Empresas Parceiras "
              className="w-[11.25rem] h-[5rem]"
            />
            <img
              src="/assets/img/empresas_parceiras_imagem_7.svg"
              alt="Empresas Parceiras "
              className="w-[11.25rem] h-[5rem]"
            />
            <img
              src="/assets/img/empresas_parceiras_imagem_10.svg"
              alt="Empresas Parceiras "
              className="w-[11.25rem] h-[5rem]"
            />
            <img
              src="/assets/img/empresas_parceiras_imagem_9.svg"
              alt="Empresas Parceiras "
              className="w-[11.25rem] h-[5rem]"
            />
            <img
              src="/assets/img/empresas_parceiras_imagem_11.svg"
              alt="Empresas Parceiras "
              className="w-[11.25rem] h-[5rem]"
            />
          </div>
          <div className="grid lg:grid-cols-5 grid-cols-3 gap-4 items-center mt-8">
            <img
              src="/assets/img/empresas_parceiras_imagem_14.svg"
              alt="Empresas Parceiras "
              className="w-[11.25rem] h-[5rem]"
            />
            <img
              src="/assets/img/empresas_parceiras_imagem_13.svg"
              alt="Empresas Parceiras "
              className="w-[11.25rem] h-[5rem]"
            />
            <img
              src="/assets/img/empresas_parceiras_imagem_16.svg"
              alt="Empresas Parceiras "
              className="w-[11.25rem] h-[5rem]"
            />
            <img
              src="/assets/img/empresas_parceiras_imagem_15.svg"
              alt="Empresas Parceiras "
              className="w-[11.25rem] h-[5rem]"
            />
            <img
              src="/assets/img/empresas_parceiras_imagem_12.svg"
              alt="Empresas Parceiras "
              className="w-[11.25rem] h-[5rem]"
            />
          </div>
        </div>
      </div>
      <div className="w-full max-w-7xl md:hidden flex lg:flex-row flex-col items-center justify-around gap-8 px-4 xl:px-0 py-20 mx-auto">
        <div>
          <h3 className="w-full text-center text-slate-800 text-[34px] font-normal leading-[45.90px]">
            Empresas que aprovam e confiam na Amee
          </h3>
          <div className="grid grid-cols-3 lg:grid-cols-6 gap-4 justify-center items-center mt-8">
            <img
              src="/assets/img/empresas_parceiras_imagem_2.svg"
              alt="Empresas Parceiras "
              className="w-[9.063rem] h-[4rem]"
            />
            <img
              src="/assets/img/empresas_parceiras_imagem_3.svg"
              alt="Empresas Parceiras "
              className="w-[9.063rem] h-[4rem]"
            />
            <img
              src="/assets/img/empresas_parceiras_imagem_5.svg"
              alt="Empresas Parceiras "
              className="w-[9.063rem] h-[4rem]"
            />
            <img
              src="/assets/img/empresas_parceiras_imagem_4.svg"
              alt="Empresas Parceiras "
              className="w-[9.063rem] h-[4rem]"
            />
            <img
              src="/assets/img/empresas_parceiras_imagem_6.svg"
              alt="Empresas Parceiras "
              className="w-[9.063rem] h-[4rem]"
            />
            <img
              src="/assets/img/empresas_parceiras_imagem_13.svg"
              alt="Empresas Parceiras "
              className="w-[9.063rem] h-[4rem]"
            />
          </div>
          <div className="grid lg:grid-cols-6 grid-cols-3 gap-4 items-center mt-8">
            <img
              src="/assets/img/empresas_parceiras_imagem_8.svg"
              alt="Empresas Parceiras "
              className="w-[9.063rem] h-[4rem]"
            />
            <img
              src="/assets/img/empresas_parceiras_imagem_7.svg"
              alt="Empresas Parceiras "
              className="w-[9.063rem] h-[4rem]"
            />
            <img
              src="/assets/img/empresas_parceiras_imagem_9.svg"
              alt="Empresas Parceiras "
              className="w-[9.063rem] h-[4rem]"
            />
            <img
              src="/assets/img/empresas_parceiras_imagem_10.svg"
              alt="Empresas Parceiras "
              className="w-[9.063rem] h-[4rem]"
            />
            <img
              src="/assets/img/empresas_parceiras_imagem_11.svg"
              alt="Empresas Parceiras "
              className="w-[9.063rem] h-[4rem]"
            />
            <img
              src="/assets/img/empresas_parceiras_imagem_16.svg"
              alt="Empresas Parceiras "
              className="w-[9.063rem] h-[4rem]"
            />
          </div>
          <div className="grid lg:grid-cols-6 grid-cols-3 gap-4 items-center mt-8">
            <img
              src="/assets/img/empresas_parceiras_imagem_14.svg"
              alt="Empresas Parceiras "
              className="w-[9.063rem] h-[4rem]"
            />
            <img
              src="/assets/img/empresas_parceiras_imagem_12.svg"
              alt="Empresas Parceiras "
              className="w-[9.063rem] h-[4rem]"
            />
            <img
              src="/assets/img/empresas_parceiras_imagem_15.svg"
              alt="Empresas Parceiras "
              className="w-[9.063rem] h-[4rem]"
            />
          </div>
        </div>
      </div>
    </>
  )
}
