import axios  from "axios"
import { consorcioCocaColaSlice } from '../../store';
import { config } from "../../Config";
export const baseURL = config.url.apiEndPoint;

const api = axios.create();


export async function obterSimulacao(idDistribuidora,valorConsumo) {
  return new Promise(async (resolve, reject) => {
    const response = await api.post(`${baseURL}/simulacao/landingpage/lead/buscar`, {
      idDistribuidora,
      valorConsumo
    });
    resolve(response.data.entities);
  });
};
