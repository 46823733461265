import React from 'react'
import ProstaNaoEnviadaHero from './components/ProstaNaoEnviadaHero'
import EtapasEstrategicas from './components/EtapasEstrategicas'
import Footer from '../../../../layout/components/Footer'
import Header from '../../../../layout/components/Header'
import QueroOrcamento from './components/QueroOrcamento'

export default function PropostaNaoEnviada() {
  return (
    <div>
      <div>
        <Header />
      </div>
      <section>
        <ProstaNaoEnviadaHero />
      </section>
      <section>
        <QueroOrcamento />
      </section>
      <section>
        <EtapasEstrategicas />
      </section>
      <Footer />
    </div>
  )
}
