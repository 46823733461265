//Importação React
import { Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import FooterAdesao from '../../../../../components/adesao/adesao_footer'
import AdesaoHeader from '../../../../../components/adesao/adesao_header'
import { Telefone } from '../../../../../components/control'
import { Input } from '../../../../../components/control/Input'
import { retirarMascaraTelefone } from '../../../../../components/control/formatarCampos'
import numeral from '../../../../../components/control/numeralBRL'
import { obterSimulacao } from '../../../../../pages/api/simulacao'
import {
  alterarSimulacaoLeadv1,
  incluirSimulacaoLead,
} from '../../../../../pages/api/simulacaoLead'
import { EnviarInscricao, Voltar } from '../../../../../store'
import '../cadastro.css'
import EconomiaTotal from '../components/EconomiaTotal'

const AdesaoFormSchema = Yup.object().shape({
  nome: Yup.string().required('Nome é obrigatório'),
  email: Yup.string().email('E-mail inválido').required('E-mail é obrigatório'),
  telefone: Yup.string().required('Telefone é obrigatório'),
  // strParametro7: Yup.string().required('Celular é obrigatório'),
})

// const nomeRequired = (message) => <p className="help is-danger text-red">{message}</p>;

export default function Cadastro1() {
  const fluxoConsorcio = useSelector((state) => state)
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const { decParametro3, decParametro5, decParametro4, intParametro1 } =
    useParams()

  console.log(decParametro3, decParametro4, decParametro5, intParametro1)

  const [valor1Mes, setValor1Mes] = useState(null)
  const [valor12Mes, setValor12Mes] = useState(null)
  const [valorSemDesconto, setValorSemDesconto] = useState(null)
  const [idDistribuidora, setIdDistribuidora] = useState(null)
  const [consumoMedio, setConsumoMedio] = useState(null)
  const [idLocal, setIdLocal] = useState(null)
  const [cpf, setCpf] = useState(null)
  const [cnpj, setCnpj] = useState(null)

  useEffect(() => {
    if (
      fluxoConsorcio.consorciosCocaCola.valor1Mes != 0 &&
      fluxoConsorcio.consorciosCocaCola.valor12Mes != 0 &&
      fluxoConsorcio.consorciosCocaCola.valorSemDesconto != 0
    ) {
      // setValor1Mes(fluxoConsorcio.consorciosCocaCola.valor1Mes)
      // setValor12Mes(fluxoConsorcio.consorciosCocaCola.valor12Mes)
      // setValorSemDesconto(fluxoConsorcio.consorciosCocaCola.valorSemDesconto)
      // var obj ={
      //   valor1Mes: fluxoConsorcio.consorciosCocaCola.valor1Mes,
      //   valor12Mes:fluxoConsorcio.consorciosCocaCola.valor12Mes,
      //   valorSemDesconto:fluxoConsorcio.consorciosCocaCola.valorSemDesconto
      // }
      // setEstado(obj);
    }

    const documentoStorage = JSON.parse(
      localStorage.getItem('@SolarCocaCola:Dados')
    )

    if (documentoStorage) {
      const { cpf, cnpj } = documentoStorage

      if (cpf != null) {
        setCpf(cpf)
        // setTipoLead('F');
      }

      if (cnpj != null) {
        setCnpj(cnpj)
        // setTipoLead('J');
      }
    }

    // const wizard = localStorage.getItem('wizard')
    // const objWizard = JSON.parse(wizard)
    // // wizard !== "true"
    // if (objWizard != null) {
    //   if (objWizard.ver !== true || objWizard.pagina !== 1) {
    //     window.location.href = 'http://localhost:3000/'
    //   }
    // } else {
    //   window.location.href = 'http://localhost:3000/'
    // }

    // var valormes1 =
    //   localStorage.getItem('valor1Mes') !== null
    //     ? parseFloat(localStorage.getItem('valor1Mes'))
    //     : null
    // var valormes12 =
    //   localStorage.getItem('valor12Mes') !== null
    //     ? parseFloat(localStorage.getItem('valor12Mes'))
    //     : null
    // var valorSemDesconto =
    //   localStorage.getItem('valorDesconto') !== null
    //     ? parseFloat(localStorage.getItem('valorDesconto'))
    //     : null
    // var idDistribuidora =
    //   localStorage.getItem('idDistribuidora') !== null
    //     ? localStorage.getItem('idDistribuidora')
    //     : null
    // var consumoMedio =
    //   localStorage.getItem('consumoMedio') !== null
    //     ? parseFloat(localStorage.getItem('consumoMedio'))
    //     : null

    // setValor1Mes(valormes1)
    // setValor12Mes(valormes12)
    // setValorSemDesconto(valorSemDesconto)
    // setIdDistribuidora(idDistribuidora)
    // setConsumoMedio(consumoMedio)
  }, [])

  useEffect(() => {
    console.log(decParametro3, intParametro1, 'aaaaaaaa')
    obterSimulacao(intParametro1, parseFloat(decParametro3)).then(function (
      response
    ) {
      setValor1Mes(response.plano)
      setValor12Mes(response.meses12)
      setValorSemDesconto(response.valorFornecido)
    })
  }, [decParametro3, intParametro1])

  console.log(valor1Mes)

  return (
    <>
      <Formik
        initialValues={{
          nome: '',
          email: '',
          telefone: '',
          strParametro7: '',
        }}
        enableReinitialize={true}
        validationSchema={AdesaoFormSchema}
        onSubmit={(values) => {
          values.telefone = retirarMascaraTelefone(values.telefone)
          values.strParametro7 = retirarMascaraTelefone(values.strParametro7)
          // if (fluxoConsorcio.consorciosCocaCola.idLead == null) {
          incluirSimulacaoLead({
            nome: values.nome,
            cnpj: cnpj,
            cpf: cpf,
            email: values.email,
            telefone: values.telefone,
            strParametro7: values.strParametro7,
            intParametro1:
              idDistribuidora === null ? intParametro1 : idDistribuidora,
            decParametro3:
              valorSemDesconto === null
                ? parseFloat(decParametro3)
                : valorSemDesconto,
            decParametro4:
              consumoMedio === null ? parseFloat(decParametro4) : consumoMedio,
            decParametro5:
              valor1Mes === null ? parseFloat(decParametro5) : valor1Mes,
            tipoParceiro: 1,
          }).then(function (response) {
            var id = response.id
            localStorage.setItem('idLeadTela', id)
            setIdLocal(id)
            dispatch(
              EnviarInscricao({
                nome: values.nome,
                email: values.email,
                telefone: values.telefone,
                strParametro7: values.strParametro7,
                idLead: parseInt(response.id),
              })
            )
            localStorage.removeItem('wizard')
            localStorage.setItem(
              'wizard',
              JSON.stringify({ ver: true, pagina: 2 })
            )
            navigate('/adesao/documento-consorcio/null')
          })
          // }
          // else {
          //   alterarSimulacaoLeadv1(
          //     fluxoConsorcio.consorciosCocaCola.idLead,
          //     values.nome,
          //     values.email,
          //     values.telefone,
          //     values.strParametro7,
          //     idDistribuidora,
          //     valorSemDesconto,
          //     consumoMedio
          //   ).then(function (response) {
          //     navigate('/adesao/documento-consorcio')
          //     dispatch(
          //       EnviarInscricao({
          //         nome: values.nome,
          //         email: values.email,
          //         telefone: values.telefone,
          //         strParametro7: values.strParametro7,
          //         idLead: fluxoConsorcio.consorciosCocaCola.idLead,
          //       })
          //     )
          //   })
          // }
        }}>
        {({ handleSubmit, errors, setFieldValue }) => (
          <>
            <AdesaoHeader progress={'20%'} />
            <main className="bg-[#f0efea] px-5 md:px-14 py-10 md:py-16 md:pt-20 md:mt-0">
              <section className=" flex justify-center items-center">
                <div className="grid md:grid-cols-6 items-start gap-5">
                  <div className="md:col-span-4 bg-white drop-shadow-xl p-8 w-full rounded-xl border border-gray-300">
                    <div>
                      <h3 className="font-bold text-2xl md:text-3xl md:w-3/4 text-zinc-800 mb-10">
                        Preencha os dados e entraremos em contato
                      </h3>
                      <Form className="flex flex-col gap-4">
                        <Field
                          component={Input}
                          name="nome"
                          label="Nome"
                          required
                          placeholder="Insira seu nome"
                        />
                        <Field
                          component={Input}
                          name="email"
                          label="E-mail"
                          required
                          placeholder="Insira seu e-mail"
                        />
                        <Field
                          component={Telefone}
                          name="telefone"
                          label="Telefone"
                          placeholder="Insira seu telefone"
                        />
                        {/* <Field
                          component={Telefone}
                          name="strParametro7"
                          label="Celular"
                          placeholder="Insira seu celular"
                        /> */}

                        <div className="flex justify-end">
                          <a
                            type="button"
                            className="px-7 py-3 bg-primary text-white font-bold w-fit mt-3 rounded-full hover:opacity-90 cursor-pointer"
                            // href="/adesao/documento-consorcio"
                            onClick={() => handleSubmit()}>
                            Continuar
                          </a>
                        </div>
                      </Form>
                    </div>
                  </div>
                  <div className="md:col-span-2 bg-white drop-shadow-xl p-8 w-full rounded-xl border border-gray-300">
                    <div>
                      <h3 className="text-2xl md:text-3xl font-bold text-zinc-800 mb-6">
                        Economia Atual
                      </h3>
                      <p className="mb-1 text-lg text-zinc-700">
                        Contrato de{' '}
                        <strong className="text-bold">12 meses</strong> economia
                        projetada de:
                      </p>
                      <h3 className="font-bold text-2xl md:text-3xl text-zinc-700">
                        {'R$ ' + numeral(valor12Mes).format('0,0.00')}
                      </h3>
                      <p className="pt-5 pb-1 text-lg text-zinc-700">
                        Benefício
                      </p>
                      <h3 className="font-bold text-2xl md:text-3xl text-zinc-700">
                        15%
                      </h3>
                      <p className="pt-5 pb-1 text-lg text-zinc-700 ">
                        Valor atual da energia
                      </p>
                      <h3 className="font-bold text-2xl md:text-3xl text-zinc-500 line-through">
                        {'R$ ' + numeral(valorSemDesconto).format('0,0.00')}
                      </h3>
                      <p className="pt-5 pb-1 text-lg text-zinc-700">
                        Valor do plano
                      </p>
                      <h3 className="font-bold text-2xl md:text-3xl text-zinc-700">
                        {'R$ ' + numeral(valor1Mes).format('0,0.00')}
                      </h3>
                    </div>
                  </div>
                  {/* <EconomiaTotal estado={estado}/> */}
                </div>
              </section>
            </main>
            <FooterAdesao />
          </>
        )}
      </Formik>
    </>
  )
}
