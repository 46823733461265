import React from 'react'
import { Routes, Route } from 'react-router-dom'

import PropostaNaoEnviada from './app/modules/pages/proposta-nao-enviada/PropostaNaoEnviada'
import PropostaEnviada from './app/modules/pages/proposta-enviada/PropostaEnviada'
import Cadastro1 from './app/modules/pages/cadastro/cadastro1/Cadastro1'
import Cadastro2 from './app/modules/pages/cadastro/cadastro2/Cadastro2'
import Cadastro3 from './app/modules/pages/cadastro/cadastro3/Cadastro3'
import Cadastro4 from './app/modules/pages/cadastro/cadastro4/Cadastro4'
import Home from './app/modules/pages/home/home'
import RedirectProposta from './app/modules/pages/redirect/RedirectProposta'

export function URLRoutes() {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route
        exact
        path="/adesao/dados-consorcio/:decParametro3/:decParametro4/:decParametro5/:intParametro1"
        element={<Cadastro1 />}
      />
      <Route
        exact
        path="/adesao/documento-consorcio/:hash"
        element={<Cadastro2 />}
      />
      <Route exact path="/adesao/termos" element={<Cadastro3 />} />
      <Route exact path="/proposta-enviada" element={<PropostaEnviada />} />
      <Route
        exact
        path="/proposta-nao-enviada"
        element={<PropostaNaoEnviada />}
      />
      <Route exact path="/redirect/proposta" element={<RedirectProposta />} />
      <Route exact path="/adesao/obrigado" element={<Cadastro4 />} />
    </Routes>
  )
}
