import React, { createContext, useMemo, useState, useContext } from 'react'
import { LinearProgress } from '@mui/material'

const BackdropContext = createContext()

export default function BackdropProvider({ children }) {
  const [showBackdrop, setShowBackdrop] = useState(false)

  const providerValue = useMemo(
    () => ({
      setShowBackdrop,
    }),
    []
  )

  return (
    <BackdropContext.Provider value={providerValue}>
      {showBackdrop && (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-[99999999] overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
          <img src="/assets/img/vivo_logo.svg" alt="Logotipo Vivo Valoriza" />
          <div className="relative w-1/6  bg-gray-200 rounded">
            <div className="w-full absolute top-0 h-4 rounded shim-green" />
          </div>
          <div className="w-36">
            <LinearProgress color="primary" />
          </div>
        </div>
      )}
      {children}
    </BackdropContext.Provider>
  )
}

/**
 * @param setShowBackdrop para abrir e fechar o loading
 */
export const useBackdrop = () => useContext(BackdropContext)
