import React from 'react'

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  accordionClasses,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export default function Faq() {
  return (
    <>
      <div className="w-full max-w-7xl flex lg:flex-row flex-col items-center justify-center gap-8 px-4 xl:px-0 py-20 lg:pt-14 pt-4 mx-auto">
        <div className="z-20">
          <h3 className="w-full max-w-[49.5rem] text-center text-slate-800 lg:text-5xl text-4xl font-medium  lg:leading-[64.80px]">
            FAQ
          </h3>
          <div className="w-full max-w-[49.5rem] mt-16 py-5 px-2">
            <Accordion
              variant="outlined"
              sx={{
                border: 'none',
                borderRadius: '0px',
                borderTop: '1px solid #CED6D7',
                [`&.${accordionClasses.root}`]: {
                  borderTop: 'none !important',
                },
              }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header">
                O que é a parceria entre Amee e Vivo no programa Vivo Valoriza
                Empresas?
              </AccordionSummary>
              <AccordionDetails>
                <div className="space-y-3">
                  <div className="w-full flex justify-start items-start gap-3">
                    <p className="text-slate-800">
                      A parceria visa oferecer aos clientes do Vivo Valoriza
                      Empresas benefícios exclusivos, incluindo um desconto de
                      15% na contratação dos serviços de gestão e auditorias de
                      faturas da Amee.
                    </p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              variant="outlined"
              sx={{
                border: 'none',
                borderRadius: '0px',
                borderTop: '1px solid #CED6D7',
              }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header">
                Quais são os benefícios exclusivos dessa parceria?
              </AccordionSummary>
              <AccordionDetails>
                <div className="space-y-3">
                  <div className="w-full flex justify-start items-center gap-3">
                    <p className="text-slate-800">
                      Clientes do Vivo Valoriza Empresas recebem 15% de desconto
                      nos serviços de gestão e auditorias de faturas da Amee,
                      que incluem processamento de faturas e processo de
                      ressarcimento de valores cobrados indevidamente pelas
                      concessionárias de energia.
                    </p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              variant="outlined"
              sx={{
                border: 'none',
                borderRadius: '0px',
                borderTop: '1px solid #CED6D7',
              }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header">
                Como posso ter acesso aos benefícios oferecidos pela Amee?
              </AccordionSummary>
              <AccordionDetails>
                <div className="space-y-3">
                  <div className="w-full flex justify-start items-center gap-3">
                    <p className="text-slate-800">
                      Você pode acessar os benefícios através do aplicativo Vivo
                      Valoriza Empresas.
                    </p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              variant="outlined"
              sx={{
                border: 'none',
                borderRadius: '0px',
                borderTop: '1px solid #CED6D7',
              }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header">
                Como faço para solicitar o diagnóstico inicial com a Amee?
              </AccordionSummary>
              <AccordionDetails>
                <div className="space-y-3">
                  <div className="w-full flex justify-start items-center gap-3">
                    <p className="text-slate-800">
                      Basta acessar o link da Amee fornecido através do
                      aplicativo Vivo Valoriza Empresas, preencher seus dados e
                      solicitar o diagnóstico inicial.
                    </p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              variant="outlined"
              sx={{
                border: 'none',
                borderRadius: '0px',
                borderTop: '1px solid #CED6D7',
              }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header">
                O que inclui o diagnóstico inicial oferecido pela Amee?
              </AccordionSummary>
              <AccordionDetails>
                <div className="space-y-3">
                  <div className="w-full flex justify-start items-center gap-3">
                    <p className="text-slate-800">
                      O diagnóstico inicial inclui uma análise das suas faturas
                      de energia e a identificação de pontos de melhoria,
                      destacando onde você pode economizar e otimizar seus
                      custos.
                    </p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              variant="outlined"
              sx={{
                border: 'none',
                borderRadius: '0px',
                borderTop: '1px solid #CED6D7',
              }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header">
                O diagnóstico é gratuito?
              </AccordionSummary>
              <AccordionDetails>
                <div className="space-y-3">
                  <div className="w-full flex justify-start items-center gap-3">
                    <p className="text-slate-800">
                      Sim, o diagnóstico da sua conta é completamente gratuito. 
                    </p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              variant="outlined"
              sx={{
                border: 'none',
                borderRadius: '0px',
                borderTop: '1px solid #CED6D7',
              }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header">
                O que é a auditoria de faturas e como funciona o processo de
                ressarcimento?
              </AccordionSummary>
              <AccordionDetails>
                <div className="space-y-3">
                  <div className="w-full flex justify-start items-center gap-3">
                    <p className="text-slate-800">
                      A auditoria de faturas é o serviço que analisa suas contas
                      para identificar inconsistências e cobranças indevidas. O
                      processo de ressarcimento é a solicitação dos valores
                      cobrados indevidamente pelas concessionárias de energia,
                      para que você recupere os valores devidos.
                    </p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              variant="outlined"
              sx={{
                border: 'none',
                borderRadius: '0px',
                borderTop: '1px solid #CED6D7',
              }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header">
                Como posso solicitar um orçamento ou atendimento de um
                especialista da Amee?
              </AccordionSummary>
              <AccordionDetails>
                <div className="space-y-3">
                  <div className="w-full flex justify-start items-center gap-3">
                    <p className="text-slate-800">
                      Após o diagnóstico inicial, você poderá solicitar um
                      orçamento detalhado ou atendimento especializado
                      diretamente através do WhatsApp da Amee.
                    </p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              variant="outlined"
              sx={{
                border: 'none',
                borderRadius: '0px',
                borderTop: '1px solid #CED6D7',
              }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header">
                Como funciona a jornada de contratação?
              </AccordionSummary>
              <AccordionDetails>
                <div className="space-y-3">
                  <div className="w-full flex justify-start items-center gap-3">
                    <p className="text-slate-800">
                      Toda a jornada é conduzida digitalmente, desde a
                      solicitação do diagnóstico até a contratação dos serviços.
                      Utilizamos automação de e-mails para mantê-lo informado em
                      cada etapa e facilidades de contratação digital para
                      finalizar processos de forma rápida e eficiente.
                    </p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              variant="outlined"
              sx={{
                border: 'none',
                borderRadius: '0px',
                borderTop: '1px solid #CED6D7',
              }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header">
                Como acompanharei os serviços contratados?
              </AccordionSummary>
              <AccordionDetails>
                <div className="space-y-3">
                  <div className="w-full flex justify-start items-center gap-3">
                    <p className="text-slate-800">
                      O seu pacote de entregas inclui um dashboard onde você
                      poderá acompanhar passo a passo tudo que a Amee fará por
                      você.
                    </p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              variant="outlined"
              sx={{
                border: 'none',
                borderRadius: '0px',
                borderTop: '1px solid #CED6D7',
              }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header">
                Quem posso contatar em caso de dúvidas ou problemas?
              </AccordionSummary>
              <AccordionDetails>
                <div className="space-y-3">
                  <div className="w-full flex justify-start items-center gap-3">
                    <p className="text-slate-800">
                      Para dúvidas ou problemas na utilização dos benefícios,
                      você pode entrar em contato diretamente com o atendimento
                      ao cliente da Amee.
                    </p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              variant="outlined"
              sx={{
                border: 'none',
                borderRadius: '0px',
                borderTop: '1px solid #CED6D7',
              }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header">
                Posso cancelar os serviços após a contratação? Quais são as
                condições?
              </AccordionSummary>
              <AccordionDetails>
                <div className="space-y-3">
                  <div className="w-full flex justify-start items-center gap-3">
                    <p className="text-slate-800">
                      Sim, você pode cancelar os serviços. As condições de
                      cancelamento serão detalhadas no contrato que você
                      assinará com a Amee. 
                    </p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              variant="outlined"
              sx={{
                border: 'none',
                borderRadius: '0px',
                borderTop: '1px solid #CED6D7',
              }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header">
                Quais são os valores dos serviços para clientes do Vivo
                Valoriza?
              </AccordionSummary>
              <AccordionDetails>
                <div className="space-y-3">
                  <div className="w-full flex justify-start items-center gap-3">
                    <p className="text-slate-800">
                      <div className="w-full max-w-[684px] text-[#142a42] text-base font-normal font-['Plus Jakarta Sans'] leading-normal">
                        O valor varia de acordo com as ações que precisaremos
                        tomar para ajudar sua empresa, mas nosso preço é a
                        partir de R$6,00 ao mês.
                        <br />
                        <br />
                        Confira a tabela completa:
                        <br />
                        <br />
                        Baixa Tensão / Geração Distribuída (BT / GD):
                        <br />1 fatura:
                        <br />
                        Valor Unitário: R$ 15,00
                        <br />
                        Valor Cliente Vivo Valoriza: R$ 12,75
                        <br />
                        <br />
                        De 2 até 5 faturas:
                        <br />
                        Valor Unitário: R$ 13,50
                        <br />
                        Valor Cliente Vivo Valoriza: R$ 11,48
                        <br />
                        <br />
                        De 6 até 10 faturas:
                        <br />
                        Valor Unitário: R$ 12,00
                        <br />
                        Valor Cliente Vivo Valoriza: R$ 10,20
                        <br />
                        <br />
                        De 11 até 20 faturas:
                        <br />
                        Valor Unitário: R$ 10,50
                        <br />
                        Valor Cliente Vivo Valoriza: R$ 8,93
                        <br />
                        <br />
                        De 21 até 50 faturas:
                        <br />
                        Valor Unitário: R$ 9,00
                        <br />
                        Valor Cliente Vivo Valoriza: R$ 7,65
                        <br />
                        <br />
                        De 51 até 100 faturas:
                        <br />
                        Valor Unitário: R$ 7,50
                        <br />
                        Valor Cliente Vivo Valoriza: R$ 6,38
                        <br />
                        101+ faturas:
                        <br />
                        Valor Unitário: R$ 6,00
                        <br />
                        Valor Cliente Vivo Valoriza: R$ 5,10
                        <br />
                        <br />
                        Média Tensão / Mercado Livre:
                        <br />1 fatura:
                        <br />
                        Valor Unitário: R$ 500,00
                        <br />
                        Valor Cliente Vivo Valoriza: R$ 425,00
                        <br />
                        <br />
                        De 2 até 5 faturas:
                        <br />
                        Valor Unitário: R$ 450,00
                        <br />
                        Valor Cliente Vivo Valoriza: R$ 382,50
                        <br />
                        <br />
                        De 6 até 10 faturas:
                        <br />
                        Valor Unitário: R$ 400,00
                        <br />
                        Valor Cliente Vivo Valoriza: R$ 340,00
                        <br />
                        <br />
                        De 11 até 20 faturas:
                        <br />
                        Valor Unitário: R$ 350,00
                        <br />
                        Valor Cliente Vivo Valoriza: R$ 297,50
                        <br />
                        <br />
                        21+ faturas:
                        <br />
                        Valor Unitário: R$ 300,00
                        <br />
                        Valor Cliente Vivo Valoriza: R$ 255,00
                        <br />
                      </div>
                    </p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              variant="outlined"
              sx={{
                border: 'none',
                borderRadius: '0px',
                borderTop: '1px solid #CED6D7',
              }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header">
                Preciso pagar alguma taxa?
              </AccordionSummary>
              <AccordionDetails>
                <div className="space-y-3">
                  <div className="w-full flex justify-start items-center gap-3">
                    <p className="text-slate-800">
                      Caso a Amee descubra que você tem valores a serem
                      restituídos pela sua concessionária de energia você
                      precisará pagar uma taxa de ressarcimento. Esse valor muda
                      dependendo do quanto você será ressarcido.
                    </p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </>
  )
}
